import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../config/service/adminService'
import cogoToast from 'cogo-toast';

class ForgotPassword extends Component{

    constructor(props){
        super(props);
        this.state = {
            email: '',
            emailErrorMsg: ''
        }
    }


    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.setState({emailErrorMsg: ''}))
    }

    handleValidetion = () => {
        let validate = true;
        let validateEmail = this.state.email;
        var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
        let validEmail = new RegExp(pattern).test(validateEmail);


        if(validateEmail === '' || validateEmail === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Email cannot be empty.'})
        }else if(!validEmail){
            validate = false;
            this.setState({emailErrorMsg: 'Enter valid e-mail.'})
        }else{
            this.setState({emailErrorMsg: ''})
        }        
        return validate
    }

    handleResetPassword = e => {
        e.preventDefault();

        if(this.handleValidetion()){
            this.setState({loader: true});

            let obj = {
                email: this.state.email
            }

            forgotPassword(obj)
            .then(res=>{
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({ email:'',loader:false})
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ emailErrorMsg: response.error.errorMessage,loader:false })
                }                
            })


        }
    }

  
    render(){
        let { email, emailErrorMsg } = this.state; 
        return(
            <div className="login_wrapper">
            <div className="row h-100 no-gutters">


                <div className="col-lg-4 h-100 login_left" style={{backgroundImage: `url(${require('../../assets/img/fitPlusBackground.jpg')})`}}>
                    <div className="row no-gutters h-100 align-items-center">
                        <div className="col">
                            <img className="cm_logo" src={require('../../assets/img/logo.svg')} alt=""/>
                        </div>
                    </div>
                </div>


                <div className="col-lg-8 h-100 login_right">
                    <div className="row no-gutters h-100 align-items-center">
                        <div className="col">


                        <form onSubmit={this.handleResetPassword}>
                            <h3>Forgot Passwrod</h3>
                            <br />
                            <br />
                            <div className="form-group">
                                <input
                                    className={`form-control ${emailErrorMsg ? 'input_error' : ''}`}
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                    autoComplete="off" />

                                    {emailErrorMsg && <span className="error_msg">{emailErrorMsg}</span>}
                            </div>

                            

                            <div className="login_actions">
                            <Link to="/login" className="login_link_forgot">Login</Link>
                                <button className="btn btn-primary btn-elevate" type="submit">Send</button>
                            </div>

                        </form>


                        </div>
                    </div>
                </div>


            </div>
       </div>
        )
    }
}
export default ForgotPassword;
