import React, { Component } from 'react';
import { Tabs, Tab, Nav, Modal, FormControl, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import dateFormat from 'dateformat';
import { Loader } from '../Reuse';
import cogoToast from 'cogo-toast';
import { getUserDetails, getUserAnswers, getUserDietPlan, getAllFoods, getUserFitnessplanAdmin, getFitnessExercises, getExerciseCategories, addFitnessPlan, getUserLogs, addUserLogs, deleteDietPlan, getDietTempelateLabels, getQuestionById, updateUserAnswer, getSelectedTimeSlots, deleteFitnessPlan, addFitnessPlanTempelate, getDieticianQuestions, sendOtp, getPendingApproveDietPlan, sendEmailToPdf, updateDietPlanEndDate, getAvailableTimeSlotsOfExpert } from '../../config/service/usersService';
import { updateAllotedCalls, editUserProfile, getTrainersByAvailableSlotV2, getUserFreeSessionsLeft, getUserPaymentDueSession, setAnsweredQuestion } from '../../config/service/adminService';

import { getServices } from "../../config/service/serviceCategoriesService";
import { bookTimeSlotsOfExpert } from "../../config/service/callSchedulingService";

import Header from '../template/Header';
import AddDietPlan from './AddDietPlan';
import EditDietPlan from './EditDietPlan';
import EditFitnessPlan from './EditFitnessPlan';
import SaveTempelate from './SaveTempelate';
import DietPlanTemplate from './DietPlanTemplate';
import FitnessPlanTempelate from './FitnessPlanTempelate';
import SaveFitnessTempelate from './SaveFitnessTempelate';
import UpdateTimeSlots from './UpdateTimeSlots';
import FoodPlaceholder from '../../assets/img/FoodPlaceholder.jpg';
import UserStats from './UserStats';
import DietTrack from './DietTrack';
import Chat from '../chat/Chat';
import FitnessChat from '../chat/FitnessChat';
import { addDays } from 'date-fns';
import ExercisePlaceholder from '../../assets/img/ExercisePlaceholder.png';
import EditUserWidget from './EditUserWidget'
import { convertCmToFt, convertFtToCm, convertKgToLbs, convertLbsToKg } from "../../utils/calculator";
import { nameRegex, mobileRegex, emailRegex } from "../../utils/regexUtils";
import DietPlanCard from '../common/DietPlan';
import FitnessPlanCard from '../common/FitnessPlan';
import { getUserProductDetail } from '../../config/service/notificationService';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { scrollToTop } from '../../utils/helper';
import placeholderImage from './../../assets/img/profilePicPlaceholder.png';


const CardWrapper = props => <div className="col mb-5">{props.children}</div>;

const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const daysOption = [{ label: "Sunday", value: 1 }, { label: "Monday", value: 2 }, { label: "Tuesday", value: 3 }, { label: "Wednesday", value: 4 }, { label: "Thursday", value: 5 }, { label: "Friday", value: 6 }, { label: "Saturday", value: 7 }];

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            userDetails: '',
            BMR: '',
            BMI: '',
            note: '',
            userLogs: [],
            fitnessPlanId: '',
            errors: '',
            validationError: '',
            loader: true,
            editableQuestion: {},
            editableAnswerText: '',
            editableAnswerOption: '',
            tempUpdatedAnswers: {},
            fitnessPlanDetails: {},
            questionAnswer: [],
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midEveningDietList: [],
            midMorningDietList: [],
            bedTimeDietList: [],
            dietPlanDetails: {},
            dietTemplateLabels: [],
            isOpenPopup: false,
            deleteDietPlanPopUp: false,
            editFitnessPlanPopUp: false,
            deleteFitnessPlanPopUp: false,
            saveDietPlanTempelatePopUp: false,
            foodItems: [],
            fitnessExercise: [],
            exerciseInputArray: [""],
            exerciseList: [],
            exerciseCategoryList: [],
            selectedFitnessExercise: [""],
            createExercisePlanPopup: false,
            openEditPopUp: false,
            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            activeTab: this.props.match.params.tab || '',
            selectedTimeSlots: [],
            daysOption,
            isUnReadMessage: false,
            saveFitnessTempelatePopup: false,
            otherQuestions: [],
            recommendedCalorie: 0,
            recommendedTime: 0,
            recommendedDistance: 0,
            editCallsAllotedPopup: false,
            allotedCalls: '',
            allTimeSlots: [],
            editTimeSlotsPopup: false,
            isUserEditable: false,
            editLoader: false,
            otpActive: false,
            selectedUserData: {},
            isFoundAlreadyPendingApproveDietPlan: false,
            messageType: 'diet',
            userProductsList: [],
            dietPlanEndDate: null,
            userDietPlan: null,

            isOpenBookSchedulePopup: false,
            isAvailabilityTimeSlotPopup: false,
            serviceCount: 100,
            pageNo: 1,
            videoSessionType: 1,
            dayOfWeek: '',
            availableTimeSlotMsg: '',
            paymentDue: false,
            freeAlreadyTaken: false,
            serviceCategories: [],
            serviceLists: [],
            getAllTimeSlots: [],
            getAllExpertAvailableTimeSlots: [],
            selectedServiceInfo: {},
            userFreeSession: {},
            searchScheduleDate: moment().add(1, 'd').startOf('day').valueOf(),
            selectScheduleStartDateFrom: moment().add(1, 'd').startOf('day').valueOf(),
            selectScheduleStartDateTo: moment().add(1, 'd').endOf('day').valueOf(),
            setDateFrom: new Date("2021/16/09"),
            type: 1,
        }
    }

    validFromChange = date => this.setState({ validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)) });
    validToChange = date => this.setState({ validTo: new Date(new Date(date).setHours(23, 59, 59, 0)) });


    handleOpenPoup = () => this.setState({ isOpenPopup: !this.state.isOpenPopup });
    handleOpenEditPoup = () => this.setState({ openEditPopUp: !this.state.openEditPopUp });
    handleSaveTempelate = () => this.setState({ saveDietPlanTempelatePopUp: !this.state.saveDietPlanTempelatePopUp });
    handleSaveFitnessTempelate = () => this.setState({ saveFitnessTempelatePopup: !this.state.saveFitnessTempelatePopup });

    handleSendMail = (e) => {
        this.setState({ loader: true })
        let params = {
            params: { dietOfUserId: this.props.match.params.id }
        }
        sendEmailToPdf(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.handleClose();
                    this.setState({ loader: false })
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.handleClose();
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    deleteDietPlan = (e) => {
        let { deleteDietPlanPopUp } = this.state
        this.setState({ deleteDietPlanPopUp: !deleteDietPlanPopUp })
    }

    submitDeleteDietPlan = (e) => {
        e.preventDefault();
        const { dietPlanDetails } = this.state
        let paramsObj = {
            dietPlanId: dietPlanDetails.dietPlanId
        }
        this.setState({ loader: true })
        deleteDietPlan(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getUserDietPlanFunc(); //loader will off in this function
                    this.handleClose();
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.handleClose();
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    submitDeleteFitnssPlan = (e) => {
        e.preventDefault();
        const { fitnessPlanId } = this.state
        let paramsObj = { fitnessPlanId }
        deleteFitnessPlan(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getUserFitnessplans(); //loader will off in this function
                    this.handleClose();
                    cogoToast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.handleClose();
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    openCreateExercisePlanPopup = () => this.setState({ createExercisePlanPopup: !this.state.createExercisePlanPopup })
    openEditFitnessPlanPopup = () => this.setState({ editFitnessPlanPopUp: !this.state.editFitnessPlanPopUp },
        () => {
            if (this.state.editFitnessPlanPopUp) {
                this.getAllExercises()
            }
        }
    )
    openDeleteExercisePlanPopup = () => this.setState({ deleteFitnessPlanPopUp: !this.state.deleteFitnessPlanPopUp })

    handleClose = () => this.setState({
        isOpenPopup: false, openEditPopUp: false, createExercisePlanPopup: false, exerciseInputArray: [""], validationError: '', deleteDietPlanPopUp: false,
        editFitnessPlanPopUp: false, saveDietPlanTempelatePopUp: false, editableQuestion: {}, tempUpdatedAnswers: {}, editableAnswerText: '', editableAnswerOption: '', deleteFitnessPlanPopUp: false, selectedFitnessExercise: [""], saveFitnessTempelatePopup: false, editCallsAllotedPopup: false, allotedCalls: '',
        allTimeSlots: [], editTimeSlotsPopup: false, selectedUserData: {},
        isOpenBookSchedulePopup: false, isAvailabilityTimeSlotPopup: false
    });

    getUserProfile = () => {
        getUserDetails({ params: { id: this.props.match.params.id } })
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ userDetails: res.data.responseData, loader: false })
                } else {
                    this.setState({ errors: res.data.error.errorMessage, loader: false })
                }
            })
    }

    getUserDetail = () => {
        let params = {
            params: { id: this.props.match.params.id }
        }
        getUserDetails(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    if (res.data.responseData.unReadMessages && res.data.responseData.unReadMessages.length) {
                        this.setState({ isUnReadMessage: true })
                    }// to show indicator when unread messages are there, this boolean value is used.
                    this.setState({ userDetails: res.data.responseData, loader: false }, () => {
                        this.getAllExerciseCategories();
                        this.getUserLogs(); 
                        this.getSelectedTimeSlots();
                        this.calculateBmr();
                    })
                } else {
                    this.setState({ errors: res.data.error.errorMessage, loader: false })
                }
            })
    }


    getDieticianQuestions = () => {
        getDieticianQuestions()
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const otherQuestions = response.responseData.result.map(question => ({
                        ...question,
                        text: ""
                    }));
                    this.setState({ otherQuestions: otherQuestions }, () => {
                        this.removeAnsweredQuestions();
                    })
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    getSelectedTimeSlots = () => {
        let param = {
            params: { reqOfUserId: this.props.match.params.id }
        }
        getSelectedTimeSlots(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    let { selectedTimeSlots } = response.responseData.result

                    selectedTimeSlots.map(slot => {
                        var t = this.convertTime12to24(slot.timeSlot);
                        slot.timeSlotMilliseconds = Number(t.split(':')[0]) * 60 + Number(t.split(':')[1]) * 1000;
                    })

                    selectedTimeSlots.sort((a, b) => (a.timeSlotMilliseconds > b.timeSlotMilliseconds) ? 1 : ((b.timeSlotMilliseconds > a.timeSlotMilliseconds) ? -1 : 0));
                    //To sort time slots in ascending order.
                    this.setState({ selectedTimeSlots })

                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }


    getUserQuestionAnswer = () => {
        let param = {
            params: { reqUserId: this.props.match.params.id }
        }
        const self = this;
        getUserAnswers(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const questionAnswer = response.responseData.result.questionAnswer.map(question => ({
                        ...question,
                        newAnswer: question.type === 3 ? question.text : question?.answer[0],
                        isChanged: false
                    }));
                    this.setState({ questionAnswer: questionAnswer }, () => {
                        this.getDieticianQuestions();
                    })
                }
            })
    }


    getUserDietPlanFunc = () => {
        let param = {
            params: { dietOfUserId: this.props.match.params.id }
        }

        let self = this;
        this.setState({ loader: true })
        getUserDietPlan(param)
            .then((res) => {
                let response = res.data;
                console.log('rkk response::::::::', response)
                if (response.statusCode == 1) {
                    const { result } = response.responseData;

                    if (result) {
                        console.log('rkk result::::::::', result);
                        self.setState({
                            morningSnacksList: result ? result.morningSnacksOptions : [],
                            breakfastList: result ? result.breakfastOptions : [],
                            lunchList: result ? result.lunchOptions : [],
                            dinnerList: result ? result.dinnerOptions : [],
                            midEveningDietList: result ? result.midEveningOptions : [],
                            midMorningDietList: result ? result.midMorningOptions : [],
                            bedTimeDietList: result ? result.bedTimeOptions : [],
                            dietPlanDetails: {
                                dietPlanId: result ? result._id : "",
                                perDayCalories: result ? result.perDayCalories : "",
                                recommendedMorningSnacksCalories: result ? result.recommendedMorningSnacksCalories : 0,
                                recommendedBreakfastCalories: result ? result.recommendedBreakfastCalories : 0,
                                recommendedMidMorningCalories: result ? result.recommendedMidMorningCalories : 0,
                                recommendedLunchCalories: result ? result.recommendedLunchCalories : 0,
                                recommendedMidEveningCalories: result ? result.recommendedMidEveningCalories : 0,
                                recommendedDinnerCalories: result ? result.recommendedDinnerCalories : 0,
                                recommendedBedTimeCalories: result ? result.recommendedBedTimeCalories : 0,
                                morningSnacksDietTime: result ? result.morningSnacksDietTime : "00:00",
                                breakfastDietTime: result ? result.breakfastDietTime : "00:00",
                                midMorningDietTime: result ? result.midMorningDietTime : "00:00",
                                lunchDietTime: result ? result.lunchDietTime : "00:00",
                                midEveningDietTime: result ? result.midEveningDietTime : "00:00",
                                dinnerDietTime: result ? result.dinnerDietTime : "00:00",
                                bedTimeDietTime: result ? result.bedTimeDietTime : "00:00",
                                savedAsTemplate: result ? result.savedAsTemplate : '',
                            },
                            loader: false,
                        })
                    } else {
                        this.getPendingApproveDietPlan();
                    }
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }

    getPendingApproveDietPlan = () => {
        let paramsObj = {
            params: {
                dietOfUserId: this.props.match.params.id
            }
        };

        getPendingApproveDietPlan(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    var result = response.responseData.result;
                    if (result) {
                        this.setState({
                            isFoundAlreadyPendingApproveDietPlan: true
                        })
                    } else {
                        this.setState({
                            isFoundAlreadyPendingApproveDietPlan: false
                        })
                    }
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    cogoToast.error(response.error.errorMessage)
                }
            })
    }



    getUserFitnessplans = () => {
        this.setState({ loader: true })
        let self = this;
        let obj = {
            params: {
                planOfUserId: this.props.match.params.id,
            }
        }
        getUserFitnessplanAdmin(obj).then(res => {

            if (res.data.statusCode == 1) {
                let { fitnessExerciseObject, fitnessPlanDetails, _id, recommendedTime,
                    recommendedCalorie, recommendedDistance } = res.data.responseData.result;

                self.setState({
                    fitnessExercise: fitnessExerciseObject,
                    fitnessPlanDetails: fitnessPlanDetails,
                    fitnessPlanId: _id,
                    recommendedTime,
                    recommendedCalorie,
                    recommendedDistance,
                    loader: false
                })
            } else if (res.data.statusCode == 0) {
                self.setState({ loader: false })
            }
        })
    }

    getAllExercises = () => {
        let self = this;
        getFitnessExercises()
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var exerciseList = [];
                    response.responseData.exercises.map((exercise, index) => {
                        exerciseList.push({ label: exercise.title, value: exercise._id });
                    });

                    self.setState({ exerciseList })
                } else if (response.statusCode == 0) {
                    console.log(res);
                }
            })
    }

    getAllExerciseCategories = () => {
        let self = this;
        getExerciseCategories()
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var exerciseCategoryList = [];
                    response.responseData.result.map((exerciseCategory, index) => {
                        exerciseCategoryList.push({ label: exerciseCategory.categoryTitle, value: exerciseCategory._id });
                    });
                    self.setState({ exerciseCategoryList })
                } else if (response.statusCode == 0) {
                    console.log(res);
                }
            })
    }

    getUserProductDetail = () => {
        let self = this;
        let param = {
            params: { id: this.props.match.params.id }
        }

        getUserProductDetail(param)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.setState({ userProductsList: response.responseData.result.product ? response.responseData.result.product : [] })
                    self.setState({ userDietPlan: response.responseData.result.dietPlan ? response.responseData.result.dietPlan : {} })

                    let dietPlanDetils = response.responseData.result.dietPlan;
                    let endDate = dietPlanDetils ? dietPlanDetils.dietPlanEndDate : null;
                    self.setState({ dietPlanEndDate: endDate ? new Date(endDate) : null })
                } else if (response.statusCode == 0) {
                    console.log(res);
                }
            })
    }


    getAllFoodItems = (search) => {
        let self = this;
        let paramsObj = {
            params: { search }
        }
        return getAllFoods(paramsObj)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var foodItems = [];
                    response.responseData.result.map((food, index) => {
                        foodItems.push({
                            label: food.foodName + " (" + food.calorieContent + " cal)",
                            value: food._id,
                            calorie: food.calorieContent,
                            protein: food.proteinContent,
                            fat: food.fatContent,
                            carb: food.carbContent,
                            fibre: food.fibreContent,
                            foodUnit: food.foodUnit
                        });
                    });

                    self.setState({ foodItems })
                    return foodItems
                } else if (response.statusCode == 0) {
                    console.log(res);
                }
            })
    }

    addMoreExercise = (e) => {
        const { selectedFitnessExercise } = this.state;
        selectedFitnessExercise.push({ selectedFitnessExercise: "" });
        this.setState({ selectedFitnessExercise })
    }

    onExerciseSelect = (data, index) => {
        const selectedFitnessExercise = Array.from(this.state.selectedFitnessExercise);
        selectedFitnessExercise[index] = {
            ...this.state.selectedFitnessExercise[index], exerciseId: data.value, label: data.label,
            exerciseIndex: index
        };
        this.setState({ selectedFitnessExercise, validationError: '' });
    }


    onExerciseCategorySelect = (data, index) => {
        const selectedFitnessExercise = Array.from(this.state.selectedFitnessExercise);
        selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], exerciseCategoryId: data.value };
        this.setState({ selectedFitnessExercise, validationError: '' });
    }

    removeSelectedExs = (index) => {
        let { exerciseInputArray, selectedFitnessExercise } = this.state;
        exerciseInputArray.splice(index, 1);
        selectedFitnessExercise.splice(index, 1);
        this.setState({ exerciseInputArray, validationError: '', selectedFitnessExercise })
    }

    handleFitnessPlanDetails = (event, data, index) => {
        this.setState({ validationError: '' })
        const selectedFitnessExercise = Array.from(this.state.selectedFitnessExercise);
        switch (event) {

            case "repetitions":
                selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], repetitions: data.target.value };
                this.setState({ selectedFitnessExercise })

                break;
            case "sets":
                selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], sets: data.target.value };
                this.setState({ selectedFitnessExercise })

                break;
            case "time":
                // var hms = data.target.value;   // your input string
                // var a = hms.split(':'); // split it at the colons
                // // minutes are worth 60 seconds. Hours are worth 60 minutes.
                // //"a" value will be in string, make sure to convert it into integer
                // var time = (a[0] ? a[0] : 0) * 3600 + (a[1] ? a[1] : 0) * 60 + (a[2] ? a[2] * 1 : 0);

                selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], time: data.target.value }; this.setState({ selectedFitnessExercise })
                break;

            case "description":
                selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], description: data.target.value };
                this.setState({ selectedFitnessExercise })

                break;
            case "daysSelected":
                let days = []
                data.map((day, index) => (
                    days.push(day.value)
                ))
                selectedFitnessExercise[index] = { ...this.state.selectedFitnessExercise[index], days: days };
                this.setState({ selectedFitnessExercise })
                break;

            default:
                break;
        }
    }

    submitAddFitnessPlan = (e) => {
        e.preventDefault();
        const { selectedFitnessExercise, recommendedCalorie, recommendedTime, recommendedDistance } = this.state

        let paramsObj = {
            fitnessExercises: selectedFitnessExercise,
            fitnessPlanUserId: this.props.match.params.id,
            savedAsTempelate: false,
            recommendedCalorie,
            recommendedTime: recommendedTime * 60, //Recorded in minutes but saved in seconds
            recommendedDistance: recommendedDistance * 1000, //Recorded in Kms but saved in metres
        }

        this.checkDuplicacy();

        if (!selectedFitnessExercise.length) {
            this.setState({ validationError: "Please select atleast one exercise" })
        } else if (this.handleValidation()) {
            this.setState({ validationError: "Please fill mandatory fields marked as '*'" })
        } else if (this.checkDuplicacy()) {
            this.setState({ validationError: "Same exercise can't be added twice" })
        } else {
            this.setState({ validationError: "" })
            this.addFitnessPlanApi(paramsObj)
        }
    }

    checkDuplicacy = () => {
        const { selectedFitnessExercise } = this.state;
        var valueArr = selectedFitnessExercise.map(function (item) { return item.exerciseId });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });
        return isDuplicate;
    }

    handleValidation = () => {
        const { selectedFitnessExercise } = this.state;
        let notValid = false
        selectedFitnessExercise.map((exerciseObj) => {
            let { exerciseId, exerciseCategoryId, days } = exerciseObj;
            if (!exerciseId || !exerciseCategoryId) {
                notValid = true;
            } else if (!days) {
                notValid = true;
            } else if (!days.length) {
                notValid = true;
            }
        })
        return notValid;
    }

    addFitnessPlanApi = (params) => {
        this.setState({ loader: true })
        let self = this;
        addFitnessPlan(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.getUserFitnessplans(); //Loader gets off in this api!
                    self.handleClose();
                } else if (response.statusCode == 0) {
                    self.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }

    getUserLogs = () => {
        let paramsObj = {
            params: { notesOfUserId: this.props.match.params.id }
        }
        getUserLogs(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ userLogs: response.responseData.result, loader: false })
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage);
                }
            })
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value, validationError: '' });

    submitNote = (e, noteType) => {
        e.preventDefault();
        const { note } = this.state;
        const paramsObj = {
            note,
            recordedFor: this.props.match.params.id,
            noteType
        }
        this.setState({ loader: true })
        addUserLogs(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ loader: false, note: '' })
                    cogoToast.success(response.responseData.message);
                    this.getUserLogs();
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage);
                    this.setState({ loader: false, note: '' })
                }
            })
    }


    getDietTempelateLabels = () => {
        this.setState({ loader: true });
        getDietTempelateLabels()
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ loader: false, dietTemplateLabels: response.responseData.result })
                } else if (response.statusCode == 0) {
                    cogoToast.error(response.error.errorMessage);
                    this.setState({ loader: false })
                }
            })
    }


    calculateBmi = (userDetails) => {
        //Formula:-> weight in kilograms/(height in meters)^2 
        let userHeight;
        let userWeight;
        let bmiCategory;

        if (!userDetails.height.value || !userDetails.weight.value) {
            return 'Not Data'
        }

        //Height will be always in cm, unit is to show the height in particular unit. 1=cm, 2=ft 
        userHeight = userDetails.height.value / 100 //in metres

        // Weight will be always in KGs, unit is to show the weight in particular unit. 1=kg, 2=lbs         
        userWeight = userDetails.weight.value //in Kgs

        let BMI = (userWeight / Math.pow(userHeight, 2)).toFixed(2)

        if (BMI <= 15) {
            bmiCategory = 'Very Severely underweight'
        } else if (BMI <= 16) {
            bmiCategory = 'Severely underweight'
        } else if (BMI <= 18.5) {
            bmiCategory = 'Underweight'
        } else if (BMI <= 25) {
            bmiCategory = 'Healthy'
        } else if (BMI <= 30) {
            bmiCategory = 'Overweight'
        } else if (BMI <= 35) {
            bmiCategory = 'Moderately obese'
        } else if (BMI <= 40) {
            bmiCategory = 'Severely obese'
        } else if (BMI > 40) {
            bmiCategory = 'Very severely obese'
        }
        return (BMI + " (" + bmiCategory + ")")
    }

    calculateBmr = () => {
        //Formula:-->
        // BMR for men     BMR = 66.5 + ( 13.75 × weight in kg ) + ( 5.003 × height in cm ) – ( 6.755 × age in years )
        // BMR for women  BMR = 655.1 + ( 9.563 × weight in kg ) + ( 1.850 × height in cm ) – ( 4.676 × age in years )
        const { userDetails } = this.state
        let userHeight = userDetails.height.value;  //in cm
        let userWeight = userDetails.weight.value; //in KGs
        let userAge;
        var today = new Date();
        let BMR

        var birthDate = new Date(userDetails.dob);
        userAge = today.getFullYear() - birthDate.getFullYear();

        if (!userDetails.height.value || !userDetails.weight.value || !userDetails.gender || !userDetails.dob) {
            this.setState({ BMR: 0 })
            return
        }

        switch (userDetails.gender) {
            case 1: //For Men
                BMR = ((66.5 + (13.75 * userWeight) + (5.003 * userHeight)) - (6.755 * userAge))
                break;
            case 2://For women
                BMR = ((655.1 + (9.563 * userWeight) + (1.850 * userHeight)) - (4.676 * userAge))
                break;
            default:
                BMR = ((66.5 + (13.75 * userWeight) + (5.003 * userHeight)) - (6.755 * userAge))
                break;
        }

        this.setState({ BMR: BMR.toFixed(2) })
    }

    calculateRecommendation = () => {
        const { userDetails, questionAnswer } = this.state;
        let answer = ''
        let recommendedCalories = ''
        let BMR = userDetails.bmr;

        if (BMR != 'No Data') {
            questionAnswer.filter(e => e.question === 'How could you describe your daily activity levels?').map(res => {
                answer = res.answer.length ? res.answer[0] : ''
            })

            // given by garima
            // Caloric requirement for wt maintenance
            // BMR * 1.2 for sedentary activity
            // BMR * 1.375 for light activity
            // BMR * 1.55 for moderate activity
            // BMR * 1.75 for heavy activity

            switch (answer) {
                case 'Sedentary':
                    recommendedCalories = BMR * 1.2
                    break;
                case 'Moderate':
                    recommendedCalories = BMR * 1.55
                    break;
                case 'Active':
                    recommendedCalories = BMR * 1.375
                    break;
                case 'Very Active':
                    recommendedCalories = BMR * 1.75
                    break;
                default:
                    break
            }
            if (answer && recommendedCalories) {
                return (recommendedCalories.toFixed(2) + " Calories/Day")
            } else {
                return ('-')
            }
        } else {
            return ('-')
        }
    }


    calculateAge = (userDetails) => {
        if (!userDetails.dob) {
            return 'Not Set'
        }

        var today = new Date();
        var birthDate = new Date(userDetails.dob);
        let userAge = today.getFullYear() - birthDate.getFullYear();
        return userAge
    }

    calculateTotalCalories = (dietPlanDetails) => {
        const { morningSnacksDietCalorie, breakfastDietCalorie, lunchDietCalorie, dinnerDietCalorie, midMorningDietCalorie, midEveningDietCalorie, bedTimeDietCalorie } = dietPlanDetails
        let totalCalories = 0;

        totalCalories = (morningSnacksDietCalorie + breakfastDietCalorie + lunchDietCalorie + dinnerDietCalorie + midMorningDietCalorie + midEveningDietCalorie + bedTimeDietCalorie);

        if (!dietPlanDetails.validFrom || !dietPlanDetails.validTo) {
            return 0
        }
        else {
            return totalCalories;
        }
    }

    handleTabChange = (eventKey) => {
        if (eventKey === 'message') {
            this.setState({ isUnReadMessage: false })
        }
        //When user opens message tab the unreadmessage check goes false so the no indicator is visible.
        this.setState({ activeTab: eventKey },
            () => {
                if (eventKey === "logs") {
                    this.getUserLogs();
                } else if (eventKey === 'dietPlan') {
                    this.getUserDietPlanFunc();
                } else if (eventKey === 'productDetails') {
                    this.getUserProductDetail()
                } else if (eventKey === 'questions') {
                    this.getUserQuestionAnswer()
                } else if (eventKey === 'ExercisePlan') {
                    this.getUserFitnessplans();
                } else if (eventKey === 'dietPlanTemplates') {
                    this.getDietTempelateLabels();
                }
            }
        )

    }

    componentDidMount() {

        var allcategory = [
            { value: 1, label: 'Doctor' },
            { value: 2, label: 'Dietician' },
            { value: 3, label: 'Home workout' }
        ]
        this.setState({ serviceCategories: allcategory })

        this.setDefaultTab();
        this.getUserDetail();



        // scroll to top when user reachout this component
        scrollToTop();
        this.getServicesList();
    }


    setDefaultTab() {
        let { activeTab } = this.state || this.props.location;
        this.setState({ activeTab })
    }

    removeAnsweredQuestions = () => {
        const { questionAnswer, otherQuestions } = this.state
        let filteredQuestions = [];

        otherQuestions.map(unanswered => {
            let matched = false;
            questionAnswer.map(answered => {
                if (unanswered._id == answered.questionId)
                    matched = true
            })

            if (!matched) {
                filteredQuestions.push(unanswered)
            }
        })
        this.setState({ otherQuestions: filteredQuestions })
    }

    convertToMins = (timeInSeconds) => {
        var hours = Math.floor(timeInSeconds / 3600);
        timeInSeconds %= 3600;
        var minutes = Math.floor(timeInSeconds / 60);
        var seconds = timeInSeconds % 60;
        return { hours, minutes, seconds }
    }

    editAllotedCalls = (allotedCalls) => {
        this.setState({ editCallsAllotedPopup: true, allotedCalls })
    }

    submitEditCalls = () => {
        let { allotedCalls } = this.state
        let params = { allotedCalls, reqOfUserId: this.props.match.params.id }

        if (!allotedCalls) {
            this.setState({ validationError: "Please enter the number of calls to be alloted!" })
        } else if (allotedCalls > 30) {
            this.setState({ validationError: "Max of 30 calls can be alloted to a person." })
        } else {
            updateAllotedCalls(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose();
                        this.setState({ userDetails: response.responseData.result })
                        cogoToast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    editTimeSlots = () => {
        this.setState({ editTimeSlotsPopup: true })
    }

    handleEditUser = () => {
        let { userDetails } = this.state
        let selectedUserData = JSON.parse(JSON.stringify(userDetails))

        if (userDetails.height && userDetails.height.unit === 2) {
            selectedUserData.height.value = convertCmToFt(selectedUserData.height.value)
        }

        if (userDetails.weight && userDetails.weight.unit === 2) {
            selectedUserData.weight.value = convertKgToLbs(selectedUserData.weight.value)
        }

        this.setState({ isUserEditable: true, selectedUserData })
    }

    handleEditUserOnChange = e => {
        let { name, value, checked } = e.target
        let { selectedUserData } = this.state

        switch (name) {
            case 'height':
            case 'weight':
                selectedUserData[name]['value'] = Number(value)
                break;

            case 'heightUnit':
                selectedUserData['height']['unit'] = Number(value)
                break;

            case 'weightUnit':
                selectedUserData['weight']['unit'] = Number(value)
                break;

            case 'isSmoker':
            case 'isDiabetes':
            case 'isHyperTension':
                selectedUserData['preferences'][name] = checked
                break;

            default:
                selectedUserData[name] = value
                break
        }
        this.setState({ selectedUserData, validationError: '' })
    }


    sendOtpFunc = e => {
        let { selectedUserData } = this.state
        let params = {
            countryCode: String(selectedUserData.countryCode),
            mobileNo: String(selectedUserData.mobileNo)
        }

        if (this.validateUserData()) {
            this.setState({ editLoader: true })
            sendOtp(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        cogoToast.success(response.responseData.message)
                        this.setState({ otpActive: true, editLoader: false })
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }

    submitEditUser = (e) => {
        e.preventDefault()
        let { selectedUserData, userDetails } = this.state
        if (this.validateUserData()) {
            if (selectedUserData.height && selectedUserData.height.unit === 2) {
                selectedUserData.height.value = convertFtToCm(selectedUserData.height.value)
            }

            if (selectedUserData.weight && selectedUserData.weight.unit === 2) {
                selectedUserData.weight.value = convertLbsToKg(selectedUserData.weight.value)
            }

            let isMobileEdited = false
            if (userDetails.mobileNo != selectedUserData.mobileNo) {
                isMobileEdited = true
            }

            let params = {
                firstName: selectedUserData.firstName,
                lastName: selectedUserData.lastName,
                countryCode: String(selectedUserData.countryCode),
                mobileNo: String(selectedUserData.mobileNo),
                email: selectedUserData.email,
                otp: selectedUserData.otp,
                reqOfUserId: selectedUserData._id,
                height: selectedUserData.height,
                weight: selectedUserData.weight,
                preferences: selectedUserData.preferences,
                gender: selectedUserData.gender,
                dob: selectedUserData.dob,
                isMobileEdited
            }
            this.setState({ editLoader: true })

            editUserProfile(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        cogoToast.success(response.responseData.message)
                        this.getUserProfile()
                        this.setState({ editLoader: false, isUserEditable: false, otpActive: false, selectedUserData: {} })
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }


    validateUserData = () => {
        let { firstName, lastName, mobileNo, email, dob, countryCode } = this.state.selectedUserData
        if (!nameRegex(firstName).success) {
            this.setState({ validationError: nameRegex(firstName).message })
            return false
        }
        else if (!nameRegex(lastName).success) {
            this.setState({ validationError: nameRegex(lastName).message })
            return false
        }
        else if (!mobileRegex(mobileNo).success) {
            this.setState({ validationError: mobileRegex(mobileNo).message })
            return false
        }
        else if (countryCode !== 91 && countryCode !== '91') {
            this.setState({ validationError: "Country code should be '91'" })
            return false
        }
        else if (!emailRegex(email).success) {
            this.setState({ validationError: emailRegex(email).message })
            return false
        }
        else {
            return true
        }
    }

    handleSelectDate = (date, key) => {
        if (key === 'dob') {
            let { selectedUserData } = this.state
            selectedUserData.dob = new Date(date).getTime()
            this.setState({ selectedUserData })
        }
        else {
            this.setState({ [key]: date })
        }
    }

    handleSelectDietPlanEndDate = (date) => {
        const endDate = moment(new Date(date)).valueOf();
        const params = {
            userId: this.state.userId,
            dietPlanEndDate: endDate.toString()
        }
        this.setState({ dietPlanEndDate: date })
        updateDietPlanEndDate(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {

                    cogoToast.success(response.responseData.message)

                }
                else if (response.statusCode == 0) {
                    console.log(response.error.errorMessage)
                    // this.setState({ validationError: response.error.errorMessage, editLoader: false })
                }
            })

    }

    getInstallationStatus = (type) => {
        switch (type) {
            case 1:
                return "Open"
                break;
            case 2:
                return "Close"
                break;
            case 3:
                return "Assigned"
                break;
            case 4:
                return "Cancelled"
                break;
            case 5:
                return "On Hold"
                break;
            case 6:
                return "Pending Assignment"
                break;

            default:
                return ''
        }

    }


    // start booking schedule process
    getServicesList = () => {
        let { serviceCount, pageNo } = this.state;
        let paramsObj = {
            params: {
                count: serviceCount,
                pageNo: pageNo - 1
            }
        }
        getServices(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        serviceLists: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    getUserFreeSessionsLeftInfo = () => {
        let paramsObj = {
            params: {
                userId: this.props.match.params.id
            }
        }
        getUserFreeSessionsLeft(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        userFreeSession: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    getUserPaymentDueSessionInfo = () => {
        let paramsObj = {
            params: {
                userId: this.props.match.params.id
            }
        }
        getUserPaymentDueSession(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    // paymentDue
                    if (res.data.responseData.result && res.data.responseData.result._id) {
                        this.setState({
                            paymentDue: true,
                            loader: false
                        })
                    }
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    openBookSchedulePopup = () => {
        this.getUserFreeSessionsLeftInfo();
        this.getUserPaymentDueSessionInfo();
        this.setState({ isOpenBookSchedulePopup: true, dayOfWeek: '', getAllTimeSlots: [], getAllExpertAvailableTimeSlots: [], selectedServiceInfo: {}, searchScheduleDate: moment().add(1, 'd').startOf('day').valueOf(), selectScheduleStartDateFrom: moment().add(1, 'd').startOf('day').valueOf(), selectScheduleStartDateTo: moment().add(1, 'd').endOf('day').valueOf() })
    }

    showBookSchedulePopup = () => {
        this.setState({ isOpenBookSchedulePopup: true, isAvailabilityTimeSlotPopup: false })
    }

    getAvailabilityTime = (val) => {
        const { selectScheduleStartDateFrom, selectScheduleStartDateTo } = this.state;
        this.setState({ isOpenBookSchedulePopup: false, isAvailabilityTimeSlotPopup: true, selectedServiceInfo: val }, function () {
            this.getAvailableTimeSlotsOfExpertLists();
        })
    }

    getAvailableTimeSlotsOfExpertLists = () => {
        const { freeAlreadyTaken, userFreeSession, videoSessionType, selectedServiceInfo, selectScheduleStartDateFrom, selectScheduleStartDateTo } = this.state;
        console.log('userFreeSession ', userFreeSession);
        console.log('selectedServiceInfo ', selectedServiceInfo);

        let isFreeAlreadyTaken = false;
        if (selectedServiceInfo.parentCategory == 1) {
            if (userFreeSession && userFreeSession.isDoctorSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (selectedServiceInfo.parentCategory == 2) {
            if (userFreeSession && userFreeSession.isDieticianSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (selectedServiceInfo.parentCategory == 3) {
            if (userFreeSession && userFreeSession.isTrainerSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (userFreeSession && userFreeSession.isAlreadyFreeSessionApply) {
            isFreeAlreadyTaken = true;
        }

        this.setState({
            freeAlreadyTaken: isFreeAlreadyTaken
        })

        let paramsObj = {
            params: {
                categoryId: selectedServiceInfo.parentCategory,
                videoSessionType: videoSessionType.toString(),
                dateFrom: selectScheduleStartDateFrom,
                dateTo: selectScheduleStartDateTo,
                serviceId: selectedServiceInfo._id
            }
        }

        getAvailableTimeSlotsOfExpert(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        getAllTimeSlots: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleDate = (val, key) => {
        let selectScheduleStartDateFrom = null, selectScheduleStartDateTo = null;
        selectScheduleStartDateFrom = moment(val).startOf('day').valueOf()
        selectScheduleStartDateTo = moment(val).endOf('day').valueOf()
        this.setState({ [key]: moment(val).format(), selectScheduleStartDateFrom: selectScheduleStartDateFrom, selectScheduleStartDateTo: selectScheduleStartDateTo })
    }

    handleTime = (val, key) => {
        this.setState({ [key]: val })
    }

    searchAvailableTimeSlotRequest = () => {
        // const { selectedServiceInfo, selectScheduleStartDateFrom,selectScheduleStartDateTo, selectScheduleTime } = this.state;
        this.setState({ loader: true, getAllExpertAvailableTimeSlots: [] }, function () {
            this.getTrainersByAvailableSlotV2Lists();
        })
    }

    getTrainersByAvailableSlotV2Lists = () => {
        const { videoSessionType, selectedServiceInfo, selectScheduleStartDateFrom, selectScheduleStartDateTo, selectScheduleTime } = this.state;
        console.log('selectedServiceInfo ', selectedServiceInfo);
        const setDate = new Date(selectScheduleStartDateFrom);
        const dayOfWeek = setDate.getDay();

        let paramsObj = {
            params: {
                dateFrom: selectScheduleStartDateFrom,
                dateTo: selectScheduleStartDateTo,
                dayOfWeek: dayOfWeek,
                timeSlot: selectScheduleTime.value,
                serviceId: selectedServiceInfo._id,
                videoSessionType: videoSessionType.toString()
            }
        }

        let isAvailableTimeSlot = false;
        getTrainersByAvailableSlotV2(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    res.data.responseData.result && res.data.responseData.result.length && res.data.responseData.result.map((expert, i) => {
                        if (expert.isAvailable && !expert.isLeave) {
                            expert.callTimeSlots[dayOfWeek].length && expert.callTimeSlots[dayOfWeek]
                                .map((expertimeSlot, index) => {
                                    isAvailableTimeSlot = true;
                                })
                        }
                    })

                    this.setState({
                        getAllExpertAvailableTimeSlots: res.data.responseData.result,
                        dayOfWeek: dayOfWeek,
                        loader: false
                    })

                    if (!isAvailableTimeSlot) {
                        cogoToast.error('No timeslot is available')
                    }
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    bookTimeSlotRequest = (expert, bookDetail) => {
        const { videoSessionType, selectedServiceInfo, searchScheduleDate } = this.state;
        // console.log('rkk expert:::', expert);
        // console.log('rkk bookDetail:::', bookDetail);
        let paramsObj = {
            userId: this.props.match.params.id,
            expertId: expert._id,
            serviceId: selectedServiceInfo._id,
            callDate: searchScheduleDate.toString(),
            callTimeSlot: bookDetail,
            videoSessionType: videoSessionType.toString()
        }

        bookTimeSlotsOfExpert(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    cogoToast.success(res.data.responseData.message)
                    this.setState({
                        isAvailabilityTimeSlotPopup: false,
                        loader: false
                    })
                } else {
                    cogoToast.error(res.data.error.errorMessage)
                }
            })
    }

    changeTextAns(e, question) {
        console.log('do======>', question)
        let { otherQuestions } = this.state;
        otherQuestions = otherQuestions.map(item => {
            if (item._id === question._id && item.type == 3) {
                return {
                    ...item,
                    text: e.target.value,
                }
            } else if (item._id === question._id) {
                return {
                    ...item,
                    text: e.currentTarget.value,
                    answers: [...question.answers]
                };
            } else {
                return item;
            }

        });
        this.setState({ otherQuestions });
    }

    changeTextAnsUpdate(e, question) {
        console.log("test", question)
        let { questionAnswer } = this.state;
        questionAnswer = questionAnswer.map(item => {
            if (item.questionId === question.questionId && item.type == 3) {
                return {
                    ...item,
                    newAnswer: e.target.value,
                    isChanged: true
                }
            } else if (item.questionId === question.questionId) {
                return {
                    ...item,
                    newAnswer: e.currentTarget.value,
                    isChanged: true
                };
            } else {
                return item;
            }

        });

        console.log("questionAnswer--->", questionAnswer)
        this.setState({ questionAnswer });
    }

    submitAnswer = () => {
        this.setState({ loader: true })
        const { otherQuestions } = this.state;
        let answer = otherQuestions.filter(item => item.text);
        const body = {
            ansOfUserId: this.props.match.params.id,
            answersToOptionArray: answer.map(item => {
                if (item.type === 3) {
                    return {
                        questionId: item._id,
                        text: item.text
                    }
                }
                return {
                    questionId: item._id,
                    options: [item.text]
                }
            })
        };
        this.callAnswerApi(body)
    }

    updateAnsweredQuestion = () => {
        this.setState({ loader: true })
        const { questionAnswer } = this.state;
        let answer = questionAnswer.filter(item => item.isChanged);
        const body = {
            ansOfUserId: this.props.match.params.id,
            answersToOptionArray: answer.map(item => {
                if (item.type === 3) {
                    return {
                        questionId: item.questionId,
                        text: item.newAnswer
                    }
                }
                return {
                    questionId: item.questionId,
                    options: [item.newAnswer]
                }
            })
        };
        this.callAnswerApi(body)
    }

    callAnswerApi = (body) => {
        setAnsweredQuestion(body)
            .then(res => {
                if (res?.data?.statusCode === 1) {
                    this.getUserQuestionAnswer()
                    cogoToast.success(res?.data?.responseData?.message)
                } else {
                    cogoToast.error(res?.data?.error?.errorMessage)
                }
                this.setState({ loader: false })
            })
            .catch(err => {
                console.log("err setAnswered", err);
                cogoToast.error(err?.data?.error?.errorMessage)
                this.setState({ loader: false })
            })
    }

    success = (message) => {
        Modal.success({
            content: message,
        });
    }

    render() {
        let { loader, userDetails, questionAnswer, morningSnacksList, breakfastList, lunchList, dinnerList, midEveningDietList, midMorningDietList, bedTimeDietList, fitnessExercise, createExercisePlanPopup, exerciseList, exerciseCategoryList, validationError, daysOption, selectedFitnessExercise, userLogs, note, dietPlanDetails, deleteDietPlanPopUp, editableQuestion, editableAnswerText, activeTab, selectedTimeSlots, editableAnswerOption, deleteFitnessPlanPopUp, fitnessPlanDetails, isUnReadMessage, otherQuestions, BMR, editCallsAllotedPopup, allotedCalls, editTimeSlotsPopup, isUserEditable, editLoader, otpActive, selectedUserData, isFoundAlreadyPendingApproveDietPlan, userProductsList, dietPlanEndDate, userDietPlan,
            isOpenBookSchedulePopup, serviceCategories, serviceLists, isAvailabilityTimeSlotPopup, getAllTimeSlots, getAllExpertAvailableTimeSlots, dayOfWeek, userFreeSession, paymentDue, freeAlreadyTaken } = this.state;
        const { match: { params: { id: userId } } } = this.props
        return (
            <React.Fragment>
                {loader && <Loader />}


                <div className="page_wrapper cm_user_detail_page">
                    <div className="sub_header">
                        <h3>User Profile</h3>
                        <span className="separator" />
                    </div>

                    <div className="users_list_wrapper">
                        <div className="row">
                            {userDetails && (
                                <div className="col-xl-3 col-lg-4">
                                    <div className="card_wrapper cm_window_hieght">
                                        <span className="status_badge active">Active</span>
                                        {isUserEditable ? null : <Button className="cm_edituser" onClick={this.handleEditUser}>Edit</Button>}

                                        <div className="user_img_sec">
                                            <img src={userDetails.profilePic ? userDetails.profilePic : require('../../assets/img/user.jpg')} alt="" />
                                            <h3>{userDetails.firstName} {userDetails.lastName}</h3>
                                        </div>

                                        <div className="widget_contact">
                                            <Button className="btn btn-primary btn-block mt-3" onClick={this.openBookSchedulePopup} > Book Schedule </Button>
                                        </div>

                                        {isUserEditable ? <EditUserWidget show={isUserEditable} selectedUserData={selectedUserData} onChange={this.handleEditUserOnChange}
                                            sendOtpFunc={this.sendOtpFunc} submitEditUser={this.submitEditUser} validationError={validationError}
                                            handleSelectDate={this.handleSelectDate} editLoader={editLoader} otpActive={otpActive} userDetails={userDetails} /> :

                                            <div className="user_card_detail">
                                                <div className="widget_contact">
                                                    <span className="widget_label">Email:</span>
                                                    <span className="widget_data">{userDetails.email}
                                                        <i className="cm_clone_icon fa fa-clone" onClick={() => { navigator.clipboard.writeText(userDetails.email) }} />
                                                    </span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Phone:</span>
                                                    <span className="widget_data"> +{userDetails.fullMobileNo} <i className="cm_clone_icon fa fa-clone"
                                                        onClick={() => { navigator.clipboard.writeText(userDetails.fullMobileNo) }} />
                                                    </span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Gender:</span>
                                                    <span className="widget_data">{userDetails.gender == 1 ? 'Male' : userDetails.gender == 2 ? 'Female' : 'Not Set'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Date of Birth:</span>
                                                    <span className="widget_data">{userDetails.dob ? dateFormat(userDetails.dob, "d mmmm yyyy") : 'Not Set'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Weight:</span>
                                                    <span className="widget_data">{userDetails.weight.value ? `${userDetails.weight.value} Kg` : 'Not Set'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Height:</span>
                                                    <span className="widget_data">{userDetails.height.value ? `${userDetails.height.value} cm` : 'Not Set'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Age:</span>
                                                    <span className="widget_data">{this.calculateAge(userDetails)}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Smoker:</span>
                                                    <span className="widget_data">{userDetails.preferences.isSmoker ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">HyperTension:</span>
                                                    <span className="widget_data">{userDetails.preferences.isHyperTension ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Diabetes:</span>
                                                    <span className="widget_data">{userDetails.preferences.isDiabetes ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">BMR:</span>
                                                    <span className="widget_data"> {userDetails.bmr} </span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">BMI:</span>
                                                    <span className="widget_data"> {userDetails.bmi} </span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Recommended Calories:</span>
                                                    <span className="widget_data">{this.calculateRecommendation(userDetails)}</span>
                                                </div>
                                                <div className="widget_contact">
                                                    <span className="widget_label">Alloted Calls:</span>
                                                    <span className="widget_data">
                                                        {userDetails.allotedCalls ? userDetails.allotedCalls : "Not alloted yet"}
                                                        &nbsp; <i className="fa fa-pencil-square-o cur_pointer" title="Edit"
                                                            onClick={() => this.editAllotedCalls(userDetails.allotedCalls)}
                                                        />
                                                    </span>
                                                </div>

                                                <div className="widget_contact">
                                                    <span className="widget_label">Available Time Slots:</span> &nbsp;

                                                    <div className="cm_slots_div">
                                                        {selectedTimeSlots.map((timeSlot, index) => (
                                                            <span className="badge badge-pill badge-dark cm_slots" data-placement="top" title="Time slots" key={index}>
                                                                {timeSlot.timeSlot}
                                                            </span>
                                                        ))}
                                                    </div>

                                                    <span className="widget_data">
                                                        <i className="fa fa-pencil-square-o cur_pointer"
                                                            title="Edit" onClick={() => this.editTimeSlots()} />
                                                    </span>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            )}


                            <div className="col-xl-9 col-lg-8">
                                <div className="card_wrapper cm_window_hieght pt-3">
                                    <Tabs
                                        defaultActiveKey="logs"
                                        id="uncontrolled-tab-example"
                                        activeKey={activeTab ? activeTab : 'logs'}
                                        onSelect={(tab) => this.handleTabChange(tab)}>
                                        <Tab eventKey="logs" title="Logs">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <div className="cm_left_tabs">
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first"><i className="fa fa-cutlery mr-1 side_icons" /> All logs</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"><i className="fa fa-cutlery mr-1 side_icons" /> Diet logs</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third"> <i className="fa fa-heartbeat mr-1 side_icons" />Fitness logs</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>

                                                <div className="cm_right_tabs">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <div className="form-group">
                                                                <ul className="cm_questions p-0">
                                                                    {userLogs.map((notes, i) => (
                                                                        <div className="alert alert-dark log_box" key={i}>
                                                                            <h4 className="alert-heading">{notes.note}</h4>
                                                                            <div className="row mt-3">
                                                                                <div className="col-md-6">
                                                                                    <div className="usr_log">Recorded by: {notes.recordedBy.email}</div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-0 usr_log text-right">Recorded on: {dateFormat(notes.created, "d mmmm yyyy h:MM TT")}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <div className="form-group">
                                                                <form onSubmit={(data) => this.submitNote(data, 1)}>
                                                                    <label htmlFor="note">You can submit notes about the user, here!</label>
                                                                    <textarea className="form-control" id="note" name="note" value={note} rows="4" onChange={this.handleChange}></textarea>
                                                                    <button className="btn btn-outline-dark mt-4" type="submit" disabled={!note ? true : false} >Record new note</button>
                                                                </form>
                                                            </div>
                                                            <ul className="cm_questions p-0">
                                                                {userLogs.filter(notes => notes.noteType === 1).map((notes, i) => (

                                                                    <div className="alert alert-dark log_box" key={i}>
                                                                        <h4 className="alert-heading">{notes.note}</h4>
                                                                        <div className="row mt-3">
                                                                            <div className="col-md-6">
                                                                                <div className="usr_log">Recorded by: {notes.recordedBy.email}</div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="mb-0 usr_log text-right">Recorded on: {dateFormat(notes.created, "d mmmm yyyy")}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="third">
                                                            <div className="form-group">
                                                                <form onSubmit={(data) => this.submitNote(data, 2)}>
                                                                    <label htmlFor="note">You can submit notes about the user, here!</label>
                                                                    <textarea className="form-control" id="note" name="note" value={note} rows="4" onChange={this.handleChange}></textarea>
                                                                    <button className="btn btn-outline-dark mt-4" type="submit" disabled={!note ? true : false}>Record new note</button>
                                                                </form>
                                                            </div>

                                                            <ul className="cm_questions">
                                                                {userLogs.filter(notes => notes.noteType === 2).map((notes, i) => (
                                                                    <div className="alert alert-dark log_box" key={i}>
                                                                        <h4 className="alert-heading">{notes.note}</h4>
                                                                        <p className="usr_log">Recorded by: {notes.recordedBy.email}</p>
                                                                        <p className="mb-0 usr_log">Recorded on: {dateFormat(notes.created, "d mmmm yyyy")}</p>
                                                                    </div>))}
                                                            </ul>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>

                                            </Tab.Container>

                                        </Tab>

                                        <Tab eventKey="stats" title="Stats">

                                            <Tab.Container id="left-tabs-example-stats" defaultActiveKey="first">

                                                <div className="cm_right_tabs">
                                                    <Tab.Content>

                                                        <Tab.Pane eventKey="first">
                                                            <div className="form-group">
                                                                {
                                                                    activeTab === "stats" &&
                                                                    <UserStats handleClose={this.handleClose}{...this.state} />
                                                                }
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </Tab>

                                        <Tab eventKey="dietPlan" title="Diet Plan" className="">
                                            {
                                                activeTab === "dietPlan" &&
                                                <>
                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <h2>Diet Plan <span className="history-link"><NavLink to={`/diet-history/${userId}`}>See Old Plans</NavLink></span></h2>

                                                            <h6>Total calorie target: {dietPlanDetails.perDayCalories}/day</h6>
                                                        </div>

                                                        <div className="col text-right cm_btn_space">
                                                            {morningSnacksList.length || breakfastList.length || midMorningDietList.length || lunchList.length || midEveningDietList.length || dinnerList.length || bedTimeDietList.length ?
                                                                <React.Fragment>
                                                                    <button className="btn btn-info" onClick={this.handleOpenEditPoup} >Edit Diet plan<i className="fa fa-pencil-square-o ml-1" aria-hidden="true"></i></button>

                                                                    <button className="btn btn-primary" onClick={()=>this.handleTabChange('dietPlanTemplates')} >Change Plan<i className="fa fa-arrows-h ml-1" /></button>

                                                                    <button className="btn btn-info" onClick={this.handleSendMail} >Send Mail<i className="fa fa-pencil-square-o ml-1" aria-hidden="true"></i></button>

                                                                    {dietPlanDetails.savedAsTemplate ? <button className="btn btn-success" disabled>Saved as template <i className="fa fa-check-square-o ml-1"></i></button> :
                                                                        <button className="btn btn-warning" onClick={this.handleSaveTempelate} >Save as template<i className="fa fa-plus ml-1" aria-hidden="true"></i></button>}


                                                                    {/*Delete button will be used later:-  
                                                            <button className="btn btn-danger cm_danger" onClick={this.deleteDietPlan} >Delete Diet plan <i className="fa fa-ban ml-1" />
                                                            </button> */}
                                                                </React.Fragment>

                                                                : <React.Fragment>
                                                                    {isFoundAlreadyPendingApproveDietPlan ?
                                                                        <button className="btn btn-info" onClick={() => this.handleTabChange('dietPlanTemplates')}>Pending Appoval Diet plan <i className="fa fa-eye ml-1" /> </button>
                                                                        :
                                                                        <button className="btn btn-info" onClick={this.handleOpenPoup}>Add Diet plan <i className="fa fa-plus-square ml-1" /> </button>
                                                                    }
                                                                    <button className="btn btn-info" onClick={() => this.handleTabChange('dietPlanTemplates')}>Assign from templates</button>
                                                                </React.Fragment>}

                                                        </div>



                                                    </div>


                                                    <CardWrapper>
                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Pre-Breakfast</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedMorningSnacksCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.morningSnacksDietTime ? dietPlanDetails.morningSnacksDietTime : '-'}</span>
                                                            </h6>
                                                        </div>


                                                        {!morningSnacksList.length ?
                                                            <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {morningSnacksList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.morningSnacksDiet || []} totalCalories={options.morningSnacksDietCalorie || []} key={i} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>


                                                    <CardWrapper>

                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Breakfast</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedBreakfastCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.breakfastDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {breakfastList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.breakfastDiet || []} totalCalories={options.breakfastDietCalorie || []} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>


                                                    <CardWrapper>

                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Mid Morning</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedMidMorningCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.midMorningDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {midMorningDietList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.midMorningDiet || []} totalCalories={options.midMorningDietCalorie || []} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>


                                                    <CardWrapper>
                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Lunch</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedLunchCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.lunchDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {lunchList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.lunchDiet || []} totalCalories={options.lunchDietCalorie || []} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>

                                                    <CardWrapper>

                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Mid Evening</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedMidEveningCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.midEveningDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {midEveningDietList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.midEveningDiet || []} totalCalories={options.midEveningDietCalorie || []} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>


                                                    <CardWrapper>

                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Dinner</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedDinnerCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.dinnerDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {dinnerList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.dinnerDiet || []} totalCalories={options.dinnerDietCalorie || []} />
                                                                ))}
                                                            </div>
                                                        }
                                                    </CardWrapper>



                                                    <CardWrapper>
                                                        <div className="diet-plan-heading">
                                                            <h3 className="heading-title">Bed Time</h3>
                                                            <h6>Calorie Target :
                                                                <span className="value">{dietPlanDetails.recommendedBedTimeCalories} cal</span>
                                                            </h6>
                                                            <h6>Timing :
                                                                <span className="value">{dietPlanDetails.bedTimeDietTime}</span>
                                                            </h6>
                                                        </div>

                                                        {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                                                            <div className="diet-plan-wrapper">
                                                                {bedTimeDietList.map((options, i) => (
                                                                    <DietPlanCard itrate={i} dietList={options.bedTimeDiet || []} totalCalories={options.bedTimeDietCalorie || []} />
                                                                ))}
                                                            </div>}
                                                    </CardWrapper>
                                                </>
                                            }
                                        </Tab>
                                        <Tab eventKey="productDetails" title="Product details" className="">
                                            {
                                                activeTab === "productDetails" &&

                                                <div className="">
                                                    <div className="page_wrapper">
                                                        <div className="user-card-wrapper">
                                                            <div className="card_wrapper py-0">

                                                                <div className="table-wrapper">
                                                                    <div className="call_schedules_body m-0 p-0 text-center table-responsive">
                                                                        <table className="table table-bordered ">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    {/* <th scope="col">Diet Plan Assigned Date</th>
                                                                                <th scope="col">Diet Plan End Date </th> */}
                                                                                    <th scope="col">Product Brand</th>
                                                                                    <th scope="col">Product Model </th>
                                                                                    <th scope="col">Installation Status </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                {userProductsList.map((item, index) => <tr key={index}>
                                                                                    <th scope="row">{index + 1}</th>
                                                                                    {/* <td>{userDietPlan && userDietPlan.created ? dateFormat(userDietPlan.created, "d mmmm yyyy") : 'Not Assigned'}</td>
                                                                                    <td> {userDietPlan && userDietPlan.created  ? <DatePicker
                                                                                        selected={dietPlanEndDate}
                                                                                        onChange={(date) => this.handleSelectDietPlanEndDate(date)}
                                                                                        placeholderText="diet plan end  date"
                                                                                        dateFormat="do MMM yyyy"
                                                                                        className="mr-3 form-control"
                                                                                    /> : 'Not Assigned'
                                                                                    }       
                                                                                    </td> */}

                                                                                    <td>{item.company}</td>

                                                                                    <td>{item.modelNo}</td>

                                                                                    <td>{this.getInstallationStatus(item.statusType)}</td>
                                                                                </tr>
                                                                                )}

                                                                            </tbody>
                                                                        </table>
                                                                        {!userProductsList.length ? <p className="text-danger">No Records Found!</p> : ''}

                                                                        {/* {users.length ? <Pagination
                                                                        activePage={pageNo}
                                                                        itemsCountPerPage={count}
                                                                        totalItemsCount={totalUserCount}
                                                                        pageRangeDisplayed={3}
                                                                        onChange={this.handlePageChange}
                                                                    /> : <p className="text-danger">No Users Found!</p>} */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </Tab>


                                        <Tab eventKey="questions" title="Questions">
                                            {
                                                activeTab === "questions" &&
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                    <div className="cm_left_tabs">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first">Answered Questions</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="second">Unanswered Questions</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                    <div className="cm_right_tabs inner_scroll">
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="first">

                                                                <ul className="questions_list col-lg-6 position-static">
                                                                    <button className='btn btn-primary floating-btn' onClick={this.updateAnsweredQuestion}>Submit</button>
                                                                    {questionAnswer
                                                                        .map((questions, i) => (
                                                                            <>
                                                                                <li key={i}>
                                                                                    <p className="cm_answers pl-0">{i + 1}. {questions.question}</p>
                                                                                    {
                                                                                        questions.type === 3 ?
                                                                                            <>
                                                                                                <div className={`choose-answer`}>
                                                                                                    <input className='input-box form-control'
                                                                                                        placeholder='Type your answer here...'
                                                                                                        value={questions.newAnswer} onChange={(e) => this.changeTextAnsUpdate(e, questions)} />
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            questions.type === 2 || questions.type === 1 ?
                                                                                                <>
                                                                                                    {
                                                                                                        questions?.answers.map((optionsItem, index) => (
                                                                                                            <div className="choose-answer mb-2 custom-control custom-radio" key={index}>
                                                                                                                <input type="radio" id={`${questions?.questionId}_index${index}`} name={`${questions?.questionId}_index${index}_name`} value={optionsItem?.option} className="custom-control-input"
                                                                                                                    checked={questions.newAnswer === optionsItem.option}
                                                                                                                    onChange={(e) => this.changeTextAnsUpdate(e, questions)}
                                                                                                                />
                                                                                                                <label className="custom-control-label" htmlFor={`${questions?.questionId}_index${index}`}>{optionsItem?.option}</label>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                ''
                                                                                    }
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                </ul>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="second" className='position-relative'>
                                                                <ul className="questions_list col-lg-6 position-static">
                                                                    <button className='btn btn-primary floating-btn' onClick={this.submitAnswer}>Submit</button>
                                                                    {!otherQuestions.length ? <p className="text-danger">No questions available</p> : null}
                                                                    {otherQuestions
                                                                        .map((questions, i) => (
                                                                            <>

                                                                                <li key={i}>
                                                                                    <p className="cm_answers pl-0">{i + 1}. {questions.question}</p>
                                                                                    {
                                                                                        questions.type === 3 ?
                                                                                            <>
                                                                                                <div className={`choose-answer`}>
                                                                                                    <input className='input-box form-control'
                                                                                                        placeholder='Type your answer here...'
                                                                                                        value={questions.text} onChange={(e) => this.changeTextAns(e, questions)} />
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            questions.type === 2 || questions.type === 1 ?
                                                                                                <>
                                                                                                    {
                                                                                                        questions?.answers.map((answer, index) => (
                                                                                                            <div className="choose-answer mb-2 custom-control custom-radio" key={index}>
                                                                                                                <input type="radio" id={`${questions?._id}_index${index}`} name={`${questions?._id}_index${index}_name`} value={answer?.option} className="custom-control-input"
                                                                                                                    checked={questions.text === answer.option}
                                                                                                                    onChange={(e) => this.changeTextAns(e, questions)}
                                                                                                                />
                                                                                                                <label className="custom-control-label" htmlFor={`${questions?._id}_index${index}`}>{answer?.option}</label>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                ''
                                                                                    }
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                </ul>

                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>

                                                </Tab.Container>
                                            }
                                        </Tab>



                                        <Tab eventKey="ExercisePlan" title="Exercise Plan">
                                            {
                                                activeTab === "ExercisePlan" &&
                                                <>
                                                    <div className="row mb-4">
                                                        <div className="col"><h2>Exercise Plan</h2>

                                                            {!fitnessExercise.length ? <p className="text-danger cm_centerd">No Exercise Plan allotted yet</p> : null
                                                            }
                                                        </div>
                                                        <div className="col text-right">

                                                            {!fitnessExercise.length ? <div>
                                                                <button className="btn btn-info" onClick={this.openCreateExercisePlanPopup}>Create Exercise Plan</button>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;

                                                                <button className="btn btn-info" onClick={() => this.handleTabChange('ExercisePlanTempelate')}>Assign from templates</button>
                                                            </div>
                                                                :
                                                                <div className="col  text-right cm_btn_space" >
                                                                    {/* <button className="btn btn-danger cm_danger" onClick={(val) => this.openDeleteExercisePlanPopup(fitnessExercise)}>Delete Exercise Plan</button> */}

                                                                    <button className="btn btn-primary" onClick={() => this.handleTabChange('ExercisePlanTempelate')} >Change Plan<i className="fa fa-arrows-h ml-1" /></button>

                                                                    {fitnessPlanDetails.savedAsTempelate ? <button className="btn btn-success" disabled>Saved as template <i className="fa fa-check-square-o ml-1" /></button> :

                                                                        <button className="btn btn-warning" onClick={this.handleSaveFitnessTempelate} >Save as template<i className="fa fa-plus ml-1" /></button>}


                                                                    <button className="btn btn-info" onClick={this.openEditFitnessPlanPopup}>Update Exercise Plan</button>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            fitnessExercise.map(item => (
                                                                <FitnessPlanCard item={item} />
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </Tab>


                                        <Tab eventKey="ExercisePlanTempelate" title="Exercise Plan Templates">
                                            {
                                                activeTab === "ExercisePlanTempelate" &&
                                                <FitnessPlanTempelate handleClose={this.handleClose} getUserFitnessPlan={this.getUserFitnessplans} handleTabChange={this.handleTabChange} {...this.state} />
                                            }
                                        </Tab>


                                        <Tab eventKey="dietPlanTemplates" title="Diet Plan Templates">
                                            {
                                                activeTab === "dietPlanTemplates" &&
                                                <DietPlanTemplate handleClose={this.handleClose} dietOfUserId={this.state.userId} getUserDietPlan={this.getUserDietPlanFunc} handleTabChange={this.handleTabChange} {...this.state} />
                                            }
                                        </Tab>


                                        <Tab eventKey="dietTrack" title="Diet Track">
                                            {
                                                activeTab === "dietTrack" &&
                                                <DietTrack handleClose={this.handleClose} {...this.state} />
                                            }
                                        </Tab>


                                        <Tab eventKey="message" title={isUnReadMessage && activeTab !== 'message' ? <span><i className="fa fa-commenting inside_msg_noti" />Message</span> : 'Message'}>
                                            {
                                                activeTab === "message" &&
                                                <Tab.Container id="left-tabs-example-message" defaultActiveKey="first">
                                                    <div className="cm_left_tabs">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first"><i className="fa fa-heartbeat mr-1 side_icons" /> Diet messages</Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item>
                                                            <Nav.Link eventKey="second"><i className="fa fa-heartbeat mr-1 side_icons" /> Fitness messages</Nav.Link>
                                                        </Nav.Item> */}
                                                        </Nav>
                                                    </div>

                                                    <div className="cm_right_tabs">
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="first">

                                                                {activeTab === 'message' ?
                                                                    <div className="tab_chat_wrapper">
                                                                        <Chat type="diet" {...this.props} />
                                                                    </div> : null}

                                                            </Tab.Pane>
                                                            {/* <Tab.Pane eventKey="second">
                                                                {activeTab === 'message' ?
                                                                <div className="tab_chat_wrapper">
                                                                    <FitnessChat type="fitness" assignedTrainerId={userDetails.assignedTrainer} {...this.props} />
                                                                </div> : null}
                                                        </Tab.Pane> */}
                                                        </Tab.Content>
                                                    </div>

                                                </Tab.Container>
                                            }
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <AddDietPlan handleClose={this.handleClose} getUserDietPlan={this.getUserDietPlanFunc}
                    getAllFoodItems={this.getAllFoodItems} {...this.state} />

                <EditDietPlan handleClose={this.handleClose} {...this.state} dietOfUserId={this.state.userId} updateDisabled={true} displayButton="Submit For Review" />
                {/* <EditDietPlan handleClose={this.handleClose} getUserDietPlan={this.getUserDietPlanFunc} getAllFoodItems={this.getAllFoodItems} {...this.state} /> */}

                <EditFitnessPlan handleClose={this.handleClose} getUserFitnessPlan={this.getUserFitnessplans} {...this.state} />

                <SaveTempelate handleClose={this.handleClose} getUserDietPlan={this.getUserDietPlanFunc}
                    getDietTempelateLabels={this.getDietTempelateLabels}
                    {...this.state} />

                <SaveFitnessTempelate handleClose={this.handleClose} getUserFitnessPlan={this.getUserFitnessplans} {...this.state} />

                {editTimeSlotsPopup ? <UpdateTimeSlots handleClose={this.handleClose} getSelectedTimeSlots={this.getSelectedTimeSlots} {...this.state} /> : null}

                <Modal
                    show={createExercisePlanPopup}
                    onHide={this.handleClose}
                    size="lg"
                    dialogClassName="cm_big_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Exercise Plan</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={this.submitAddFitnessPlan}>
                            <div className="form_group_wrapper">
                                <h4>Fitness Exercises</h4>

                                <div className="row">
                                    <div className="form-group col">
                                        <label>Recommended caloric output (Cal):</label>
                                        <input type="number" className="form-control" name="recommendedCalorie" min="0" onChange={this.handleChange} />
                                    </div>

                                    <div className="form-group col">
                                        <label>Recommended workout time (mins):</label>
                                        <input type="number" className="form-control" name="recommendedTime" min="0" onChange={this.handleChange} />
                                    </div>

                                    <div className="form-group col">
                                        <label>Recommended distance (Kms):</label>
                                        <input type="number" className="form-control" name="recommendedDistance" min="0" onChange={this.handleChange} />
                                    </div>
                                </div>


                                {selectedFitnessExercise.map((data, index) => (
                                    <div key={index}>
                                        <h4 className="cm_pos_rel">
                                            <b>Exercise {index + 1}</b>
                                            <span className="btn btn-danger"
                                                onClick={(val) => this.removeSelectedExs(index)}>
                                                <i className="fa fa-trash" />
                                            </span>
                                        </h4>

                                        <div className="row">
                                            <div className="form-group col">
                                                <label>Exercise *</label>
                                                <Select
                                                    isSearchable
                                                    options={exerciseList}
                                                    value={data}
                                                    onChange={(val) => this.onExerciseSelect(val, index)}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <label>Exercise Category *</label>
                                                <Select
                                                    isSearchable
                                                    options={exerciseCategoryList}
                                                    value={data.exerciseCategory}
                                                    onChange={(val) => this.onExerciseCategorySelect(val, index)}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <label>Repetitions</label>
                                                <input type="number" className="form-control" name="repetitions" onChange={(val) => this.handleFitnessPlanDetails("repetitions", val, index)} min="1" max="50" />

                                            </div>
                                            <div className="form-group col">
                                                <label>Sets</label>
                                                <input type="number" className="form-control" name="sets" onChange={(val) => this.handleFitnessPlanDetails("sets", val, index)} min="1" max="50" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col">
                                                <label>Time</label>
                                                <input type="number" className="form-control" name="time" onChange={(val) => this.handleFitnessPlanDetails("time", val, index)} min="1" max="180" />

                                                {/* <input type="time" className="form-control" name="time" onChange={(val) => this.handleFitnessPlanDetails("time", val, index)} step="1" min="00:00:30"/> */}
                                            </div>
                                            <div className="form-group col">
                                                <label>Description</label>
                                                <input type="text" className="form-control" name="description" onChange={(val) => this.handleFitnessPlanDetails("description", val, index)} />
                                            </div>
                                            <div className="form-group col">
                                                <label>Days *</label>
                                                <Select
                                                    isSearchable
                                                    isMulti
                                                    options={daysOption}
                                                    onChange={(val) => this.handleFitnessPlanDetails("daysSelected", val, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="mb-5" onClick={this.addMoreExercise}>
                                    <i className="fa fa-plus-square cur_pointer">
                                        Add more exercises</i>
                                </div>
                            </div>

                            <div className="form_group_wrapper">
                                <h4 className="text-center text-danger">{validationError}</h4>
                                <button className="btn btn-success" type="submit">Submit</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={deleteFitnessPlanPopUp}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to delete this fitness plan?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                        <button className="btn btn-danger cm_danger" onClick={this.handleClose}>No</button> &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitDeleteFitnssPlan} >Yes</button>

                    </Modal.Body>
                </Modal>


                <Modal
                    show={deleteDietPlanPopUp}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to delete this diet plan?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                        <button className="btn btn-danger cm_danger" onClick={this.handleClose}>No</button> &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitDeleteDietPlan} >Yes</button>

                    </Modal.Body>
                </Modal>


                <Modal
                    show={editCallsAllotedPopup}
                    onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Calls alloted</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                        <FormControl name="allotedCalls" onChange={this.handleChange} value={allotedCalls} max="30" type='number' />
                        <h6 className="text-center text-danger">{validationError}</h6>
                        <button className="btn btn-danger cm_danger" onClick={this.handleClose}>Cancel</button>
                        &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitEditCalls} >Update</button>
                    </Modal.Body>
                </Modal>

                <Modal show={isOpenBookSchedulePopup} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6>
                                Service Categories
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {serviceCategories && serviceCategories.length ? serviceCategories.map((category, i) => (
                            <div className="bookschedule" key={i}>
                                <div className="label-wrapper">
                                    <label className="field-label">{category.label}</label>
                                </div>
                                <div className="row" >
                                    {
                                        serviceLists && serviceLists
                                            .filter(service => service.parentCategory === category.value)
                                            .map((service, index) => {
                                                return (
                                                    <div className="col-md-3 px-2" onClick={() => this.getAvailabilityTime(service)} key={category._id + '_' + index}>
                                                        <div className="schedule-card">
                                                            <div className="scheduled-image-wrapper">
                                                                {
                                                                    service.serviceImage ? <img className="schedule-card-image" src={service.serviceImage} alt="Service image" /> : <div className="schedule-card-image">No Image</div>
                                                                }
                                                            </div>
                                                            <h6 className="person-title text-truncate">{service.serviceName}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }
                                </div>

                            </div>
                        )) : null}
                    </Modal.Body>
                </Modal>

                <Modal show={isAvailabilityTimeSlotPopup} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6>
                                <i className="fa fa-arrow-left cm_inline" onClick={() => this.showBookSchedulePopup()}></i>
                                {this.state.selectedServiceInfo ? this.state.selectedServiceInfo.serviceName : null}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!paymentDue ?
                            <>
                                <Form >
                                    <div className='row border-bottom mb-3'>
                                        <div className='col-lg-4 col-xl-5'>
                                            <FormGroup>
                                                <FormLabel>Date</FormLabel>
                                                <DatePicker
                                                    minDate={this.state.setDateFrom}
                                                    startDate={this.state.setDateFrom}
                                                    onChange={(val) => this.handleDate(val, 'searchScheduleDate')}
                                                    className="form-control"
                                                    defaultValue={moment(this.state.searchScheduleDate)}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-4 col-xl-5'>
                                            <FormGroup>
                                                <FormLabel>Timing</FormLabel>
                                                <Select
                                                    isSearchable
                                                    placeholder='Search Time Slot'
                                                    options={getAllTimeSlots}
                                                    onChange={(val) => this.handleTime(val, 'selectScheduleTime')}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-4 col-xl-2 align-self-end pb-3'>
                                            <Button className="btn btn-block" variant="success" type="button" onClick={(e) => this.searchAvailableTimeSlotRequest(e)}>Search</Button>
                                        </div>
                                    </div>
                                </Form>

                                <div className='border-bottom mb-3 trainer-table-data'>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            {getAllExpertAvailableTimeSlots && getAllExpertAvailableTimeSlots.length ? getAllExpertAvailableTimeSlots.map((expert, i) => (
                                                <tr key={i}>
                                                    {expert.isAvailable && !expert.isLeave ?
                                                        <>
                                                            <td>
                                                                <div className="trainer-data-image">
                                                                    <img src={expert.profilePic ? expert.profilePic : placeholderImage} />
                                                                </div>
                                                            </td>
                                                            <td style={{ maxWidth: "225px" }}>
                                                                <div className="trainer-data">
                                                                    <h4 className="title-trainer text-break">
                                                                        {expert.firstName} {expert.lastName}
                                                                    </h4>
                                                                    <p className="trainer-speciality text-truncate">
                                                                        <span className="label">Speciality : </span>
                                                                        <span className="value">{expert.speciality}</span>
                                                                    </p>
                                                                    <p className="trainer-rating text-truncate">
                                                                        <span className="label">Rating : </span>
                                                                        <span className="value">{expert.rating}</span>
                                                                    </p>
                                                                    <p className="trainer-fees">
                                                                        <span className="label">Fees : </span>
                                                                        <span className="value">{!freeAlreadyTaken ? expert.price : 'Free'}</span>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="slot-column">
                                                                    {
                                                                        expert.callTimeSlots[dayOfWeek].length && expert.callTimeSlots[dayOfWeek]
                                                                            .map((expertimeSlot, index) => {
                                                                                return (
                                                                                    <Button className="ml-2" variant="success" type="button" onClick={(e) => this.bookTimeSlotRequest(expert, expertimeSlot)}> {expertimeSlot} </Button>
                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </td>

                                                        </>
                                                        :
                                                        null
                                                    }

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="3" >
                                                        <p className="p-4 text-center">
                                                            Please select slot and press search
                                                        </p>
                                                    </td>
                                                </tr>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </>
                            : "This user's payment is due. so user can not book any timeslot. user has to pay his pending amount"}
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

export default User;
