import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import io from 'socket.io-client';
import { logout } from '../../config/service/adminService'
import cogoToast from 'cogo-toast';
import { getDieticianProfile, checkSession } from '../../config/service/adminService';
import Popup from '../common/Popup'
import BulkMessages from '../chat/BulkMessages'
import Calculator from '../calculator/Calculator'
import { getAllUsersLabelsAction } from '../../store/actions/usersLabels'
import { calculateBMI, calculateBMR } from '../../utils/calculator'
import { getNotificationCount, getFollowupNotificationCount } from '../../config/service/notificationService';
import { getFollowupNotificationsAction } from '../../store/actions/usersLabels'
import { getAvgDieticianRating } from '../../config/service/dieticianRatingService'


import setting from '../../config/settings/'
const socketUrl = (setting.api.socket)
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adminDetails: {},
            avgDieticianRating: '',
            toggleBulkMessagePopup: false,
            toggleBMICalculatorPopup: false,
            message: '',
            selectedUsers: [],
            users: [],
            userDetails: {
                age: "",
                height: "",
                weight: ""
            },
            calculatedData: {
                bmi: "",
                bmr: "",
            },
            notificationCount: 0,
            followupNotificationCount: 0
        }
    }

    componentDidUpdate() {
        this.checkSession();
        // this.logout();
    }

    checkSession = () => {
        checkSession()
            .then(res => {
                if (res.data.statusCode == 0) {
                    window.location.href = '/login';
                    localStorage.clear();
                    cogoToast.error(res.data.error.errorMessage);
                }
            })
    }

    /* get unread notification count */
    getNotificationCount = () => {
        let param = {
            params: { status: 0 }   //0= Unread, 1=Read , 2=All
        }
        getNotificationCount(param)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ notificationCount: res.data.responseData.totalCount })
                }
            })
    }

    /*get average dietician rating*/
    getAvgDieticianRating = () => {
        // The Dietician ID is this.state.adminDetails._id
        let param = {
            params : { dieticianId: this.state?.adminDetails?._id }
        }
        getAvgDieticianRating(param)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ avgDieticianRating: res.data.responseData.result.avgRating })
                }
            })
    }

     /* get unread notification count */
     getFollowupNotificationCount = () => {
        let param = {
            params: { status: 0 }   //0= Unread, 1=Read , 2=All
        }
        getFollowupNotificationCount(param)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ followupNotificationCount: res.data.responseData.totalCount })
                }
            })
    }

    logout = () => {
        logout()
            .then(res => {
                localStorage.clear();
                window.location.href = '/login';
                if (res.data.statusCode == 1) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else if (res.data.statusCode == 0) {
                    cogoToast.error(res.data.error.errorMessage);
                }
            })
    }


    getDieticianProfile = () => {
        this.setState({ loader: true })
        getDieticianProfile()
            .then(res => {
                if (res.data.statusCode == 1) {
                    let response = res.data.responseData.resut;
                    this.setState({
                        loader: false,
                        adminDetails: response,
                    })
                    // make a call to getAvgDietician Rating to set the avgDieticianField
                    this.getAvgDieticianRating();
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    // Set the avgRating to 0
                    this.setState({ avgDieticianRating: 0 })
                    cogoToast.error(res.data.error.errorMessage);
                }
            })
    }

    openBulkMessagePopup = () => {
        const { getAllUsersLabels, usersLabelsState: { users } } = this.props
        this.setState({ toggleBulkMessagePopup: !this.state.toggleBulkMessagePopup, message: '' }, () => {
            const { toggleBulkMessagePopup } = this.state
            if (toggleBulkMessagePopup && users.length === 0) {
                getAllUsersLabels()
            }
        })

    }

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value,
            validationError: ''
        })
    }
    sendBulkMessages = () => {
        const { message, selectedUsers } = this.state
        if (!message || !selectedUsers || selectedUsers.length === 0) {
            this.setState({
                validationError: 'Please select some users and write some text'
            })
        }
        else {
            let { message, socket } = this.state
            let userId = localStorage.getItem('userId')
            selectedUsers.forEach(element => {
                let messageObj = {
                    role: 2,
                    message,
                    senderId: userId,
                    receiverId: element.value,
                }
                socket.emit('SEND_MESSAGE', messageObj)
            });
            this.openBulkMessagePopup()
            cogoToast.success('Messages are send succesfully!')
        }
    }


    initSocket = (params) => {
        let accessToken = localStorage.getItem('accessToken')
        var socket = io.connect(socketUrl, {
            transports: ['websocket'],
            query: { accessToken: accessToken, role: 2 }
        });
        socket.on('connection', (res) => { })
        this.setState({ socket })
    }


    selectUsers = (selectedUsers) => {
        this.setState({
            selectedUsers,
            validationError: ''
        })
    }

    openBMICalculator = () => {
        this.setState({ toggleBMICalculatorPopup: !this.state.toggleBMICalculatorPopup })
        this.resetBMI()
    }

    bmiDatachange = (e) => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                [e.target.name]: e.target.value
            }
        }
        )
    }

    calculateBMI = () => {
        let { userDetails: {
            height,
            weight,
            age,
            gender
        } } = this.state

        if (!height || !weight || !age || !gender) {
            this.setState({
                validationErrors: 'All fields are mandatory'
            })
        } else {
            this.setState({
                validationErrors: ''
            })
            const bmi = calculateBMI({ height, weight })
            const bmr = calculateBMR({ height, weight, age, gender })
            this.setState({
                calculatedData: {
                    bmi,
                    bmr
                }
            })
        }
    }

    resetBMI = () => {
        this.setState({
            calculatedData: {
                ...this.state.calculatedData,
                bmi: "",
                bmr: ""
            },
            userDetails: {
                ...this.state.userDetails,
                age: "",
                height: "",
                weight: ""
            }
        }
        )
    }
    componentDidMount() {
        this.getDieticianProfile();
        this.initSocket();
        setTimeout(() => {
            this.getNotificationCount();
            this.getFollowupNotificationCount();
         }, 2000);
    }

    render() {
        const { loader, users } = this.props.usersLabelsState
        const { adminDetails, userDetails, validationErrors, calculatedData, toggleBMICalculatorPopup, validationError, toggleBulkMessagePopup, message, notificationCount, followupNotificationCount } = this.state
        return (
            <>
                <header>
                    <div className="header_menu">
                        <ul>
                            <li><NavLink exact to="/users/1">Assigned User</NavLink></li>
                            <li><NavLink to="/callSchedules">Call Schedules</NavLink></li>
                            <li><NavLink exact to="/followups">
                                <span>Follow ups</span>
                                <span className="custom-badge-menu badge">{followupNotificationCount}</span>
                            </NavLink></li>
                            {/* <li><NavLink exact to="/helpvideos">Help Videos</NavLink></li> */}
                            <li><NavLink exact to="#" onClick={this.openBulkMessagePopup}>Bulk Messages</NavLink></li>
                            <li><NavLink exact to="#" onClick={this.openBMICalculator} title="Calculate BMR and BMI">Calculator</NavLink></li>
                            <li><NavLink exact to="/notification">Notifications
                            <span className="custom-badge-menu badge">{notificationCount}</span>
                            
                            </NavLink></li>
                        </ul>
                    </div>
                    <div className="logout" >
                        <div className="item">
                            <b className="header_details">{adminDetails?.firstName + " " + adminDetails?.lastName}</b>
                            <span className="caption">Rating: {this.state.avgDieticianRating != null ? this.state.avgDieticianRating : "NAN"}* </span>
                        </div>
                        <img className="profile_img" src={adminDetails?.profilePic ? adminDetails?.profilePic : require('../../assets/img/user.jpg')} />
                        <Dropdown className="cm_dropdown">
                            <Dropdown.Toggle id="dropdown-basic" title="Logout"><i className="fa fa-ellipsis-v"></i></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Link className="dropdown-item cm_header_link" to="/profile"><i className="fa fa-user"></i> Profile</Link>
                                <Link className="dropdown-item cm_header_link" to="/change-password"><i className="fa fa-key"></i> Change Password</Link>
                                <span className="dropdown-item logout-btn" onClick={this.logout}><i className="fa fa-power-off"></i> Logout</span>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </header>
                <Popup
                    showPopup={toggleBulkMessagePopup}
                    oncloseModal={this.openBulkMessagePopup}
                    title='Bulk Message'
                    onSubmitModal={this.sendBulkMessages}
                >
                    <BulkMessages
                        users={users}
                        validationError={validationError}
                        selectUsers={this.selectUsers}
                        handleMessageChange={this.handleMessageChange}
                        message={message}
                        loader={loader}
                    />
                </Popup>
                <Popup
                    showPopup={toggleBMICalculatorPopup}
                    oncloseModal={this.openBMICalculator}
                    title='BMI/BMR Calculator'
                >
                    <Calculator
                        calculateBMI={this.calculateBMI}
                        handleChange={this.bmiDatachange}
                        validationErrors={validationErrors}
                        calculatedData={calculatedData}
                        userDetails={userDetails}
                        resetBMI={this.resetBMI}
                    />
                </Popup>
            </>
        )
    }
}


const mapStateToProps = state => ({

    usersLabelsState: state.usersLabelsState,
    followUpCount: state.usersLabelsState.followUpCount
});

const mapDispatchToProps = dispatch => ({
    getAllUsersLabels: (params) => dispatch(getAllUsersLabelsAction(params)),
    getFollowupNotificationsAction: (params) => dispatch(getFollowupNotificationsAction(params))


})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
