import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import CallSchedules from "../callSchedules/CallSchedules";
import Chat from "../chat/Chat";
import ChangePassword from "../profile/ChangePassword";
import Profile from "../profile/Profile";
import User from "../users/User";
import DietHistoryContainer from '../../containers/DietHistory'
import UsersContainner from '../../containers/Users'
import FollowupsContainer from '../../containers/Followups'
import HelpVideos from '../../containers/HelpVideos'
import Notification from "../Notifications";
import Header from './Header';


const Layout = () => {
    return (
        <>
            <Header />
            <Switch>
                <Route exact path='/user/:id/:tab?' component={User} />
                <Route exact path='/callSchedules' component={CallSchedules} />
                <Route exact path='/profile' component={Profile} />
                <Route exact path='/change-password' component={ChangePassword} />
                <Route exact path='/chat/:id' component={Chat} />
                <Route exact path='/diet-history/:id' component={DietHistoryContainer} />
                <Route exact path='/users/:pageNo?' component={UsersContainner} />
                <Route exact path='/followups' component={FollowupsContainer} />
                <Route exact path='/helpvideos' component={HelpVideos} />
                <Route path='/notification' component={Notification} />
                <Redirect exact from="/" to="/users/:pageNo?" />
            </Switch>
        </>
    );
}

export default Layout;