import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import dateFormat from 'dateformat'
import { connect } from 'react-redux'
import 'react-big-calendar/lib/css/react-big-calendar.css';

// import { getFollowups, addFollowup } from '../../config/services/holidays';
import FollowupHeader from '../components/followups/FollowupHeader';
import Header from '../components/template/Header';

import { Loader } from '../components/Reuse';
import Popup from '../components/common/Popup'
import ViewFollowup from '../components/followups/viewFollowup'
import { getFollowupsByAdminAction, editDietitianUserFollowupAction } from '../store/actions/followups'
import { getFollowupNotificationsAction } from '../store/actions/usersLabels'

import AddFollowup from '../components/followups/addFollowup'
import cogoToast from 'cogo-toast';
import { getFollowupNotification } from '../config/service/notificationService';
import { notificationViewed } from '../config/service/notificationService';
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { markReadNotifications } from '../components/common/commonFunctions'




const localizer = momentLocalizer(moment)
let formats = {
    agendaHeaderFormat: ({ start, end }, culture, local) =>
        local.format(start, 'DD MMMM YYYY', culture) + ' – ' + local.format(end, 'DD MMMM YYYY', culture)
}
class Followups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holidayList: [],
            showAddFollowupPopup: false,
            selectedDate: new Date(),
            reason: '',
            holidayObject: {},
            viewFollowupPopup: false,
            menuView: 'notifications',
            showReschedule: false,
            notes: '',
            validationError: '',
            notifications: [],
            defaultView: 'month',
            defaultDate: new Date(),
            pageNo: 0,
            hasMore: true
        }
    }

    componentDidMount() {
        const { getFollowupsByAdmin } = this.props
        const params = {
            params: {
                dateFrom: moment().startOf('day').valueOf()
            }
        }
        getFollowupsByAdmin(params);

        this.getFollowupNotification()
        this.markFollowupNotificationReadRecord()
    }

    markFollowupNotificationReadRecord = () => {
        const params = { 
                notificationType: 2
        }
        markReadNotifications(params)
            
    }

    getFollowupNotification = () => {
        const params = { 
            params: { 
                pageNo: this.state.pageNo,
                count: 10 
            } 
    }
        getFollowupNotification(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    if (res.data.responseData.result.length) {
                        res.data.responseData.result.map((item) => {
                            item.created = new Date(item.created);
                            if (item.type == 55) {
                                item.body = new Date(item.body);
                            }

                        })

                    }
                    this.setState({ notifications: res.data.responseData.result })
                    this.setState({ hasMore: this.state.notifications?.length + 1 < res.data?.responseData?.totalCount })

                    //
                }
            })
    }

    fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        let pageNoLocal = this.state.pageNo + 1;
        const params = { 
                params: { 
                    pageNo: pageNoLocal,
                    count: 10 
                } 
        }
        getFollowupNotification(params)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ notifications: this.state.notifications.concat(res.data.responseData.result), pageNo: pageNoLocal, hasMore: this.state.notifications?.length + 1 < res.data?.responseData?.totalCount })
                    //
                }
            })
        // setNotifications(prevItems => [...prevItems, ...notifications])

    };

    handleViewEvent = (event) => {
        this.setState({ followupdata: event, viewFollowupPopup: true })
    }

    closeModal = () => {
        this.setState({
            viewFollowupPopup: false
        })
    }

    closeRescheduleModal = () => {
        this.setState({
            showReschedule: false
        })
    }

    onOpenRescheduleModal = () => {
        this.setState({
            showReschedule: true
        })
    }

    editReschedule = () => {
        let { start, desc } = this.state.followupdata
        let { editFollowup } = this.props
        if (!start) {
            this.setState({ validationError: "Please select date ." })
        } else if (moment(start).milliseconds(0).valueOf() % 1800000) {
            this.setState({ validationError: "Please select time." });
        } else if (!desc) { // 
            this.setState({ validationError: " Please write some notes." })
        } else {
            let params = {
                followupDate: start,
                notes: desc,
                userId: this.state.followupdata.users._id,
                followupId: this.state.followupdata._id
            }

            editFollowup(params).then(({ res }) => {
                if (res.data.statusCode == 1) {
                    cogoToast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    cogoToast.error(res.data.error.errorMessage)
                }
                this.closeModal()
                this.closeRescheduleModal()
            }).catch((e) => {
                console.log(e)
                this.setState({ validationError: 'Oops something went wrong' })
                this.closeModal()
                this.closeRescheduleModal()
            })
        }
    }

    selectDate = (date) => {
        this.state.followupdata.start = new Date(date);
        this.state.followupdata.end = new Date(date);
        this.setState({ selectedDate: new Date(date) })
    }

    notesChangeHandler = (e) => {
        this.state.followupdata.desc = e.target.value;
        this.setState({ notes: e.target.value })
    }

    markReadNotification = (notificationId, date) => {
        let param = {
            id: notificationId,
            status: 1  //0= Unread, 1=Read , 2=All
        }
        this.setState({ menuView: 'calendar' });
        this.setState({ defaultView: 'day' })
        this.setState({ defaultDate: date })
        this.props.getFollowupNotificationsAction();
    }

    render() {
        const { viewFollowupPopup, followupdata, menuView, notes, selectedDate, showReschedule, validationError, notifications, defaultView, defaultDate, hasMore } = this.state;
        const { followupsState: { followups, loader } } = this.props
        return (
            <React.Fragment>
                {loader && <Loader />}
                <div className="page_wrapper">

                    <FollowupHeader />

                    <div className="users_list_wrapper p-0">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4">
                                <div className="card_wrapper cm_window_hieght">
                                    <div className="user_card_detail followup_menu">
                                        {/* <h3>Followups</h3> */}
                                        <ul>
                                            <li onClick={() => { this.setState({ menuView: 'notifications' }) }} className={(menuView === 'notifications') ? 'active' : ''}> Follow-up notifications</li>
                                            {/* <li onClick={() => { this.setState({ menuView: 'notifications' }) }} className={(menuView === 'notifications”') ? 'active' : ''}> Follow-up notifications</li> */}
                                            <li onClick={() => { this.setState({ menuView: 'calendar' }) }} className={(menuView === 'calendar') ? 'active' : ''}> Calendar View</li>
                                            <li onClick={() => { this.setState({ menuView: 'table' }) }} className={(menuView === 'table') ? 'active' : ''}>List View</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="card_wrapper cm_window_hieght pt-3">
                                    {(menuView === 'calendar') &&
                                        <Calendar
                                            onSelectEvent={this.handleViewEvent}
                                            culture='en-GB'
                                            localizer={localizer}
                                            events={followups}
                                            startAccessor="start"
                                            endAccessor="end"
                                            formats={formats}
                                            style={{ height: 500 }}
                                            defaultView={defaultView}
                                            defaultDate={defaultDate}
                                        />
                                    }
                                    {
                                        (menuView === 'table') &&
                                        <div className="call_schedules_body text-center">
                                            {(followups && followups.length === 0) ?
                                                <p className="text-danger">No followup Found!</p>

                                                :
                                                <table className="table table-bordered ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Mobile No</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Notes </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {followups.map((followup, i) => (
                                                            <tr key={i}>
                                                                <th scope="row">{(i + 1)}</th>
                                                                <td>{followup.users.firstName + " " + followup.users.lastName}</td>
                                                                <td>{followup.users.fullMobileNo}</td>
                                                                <td>{followup.users.email}</td>
                                                                <td>{dateFormat(followup.start, "d mmmm yyyy")}</td>
                                                                <td>{followup.desc}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    }
                                    {
                                        (menuView === 'notifications') &&
                                        <div className="call_schedules_body text-center">
                                            {(notifications && notifications.length == 0) ?
                                                <p className="text-danger">No followup Found!</p>
                                                :
                                                <>
                                                    <InfiniteScroll
                                                        dataLength={notifications ? notifications.length : 1}
                                                        next={this.fetchMoreData}
                                                        hasMore={hasMore}
                                                        loader={<h4>Loading...</h4>}
                                                    >
                                                        <ul className="list-unstyled follow-up-wrapper">
                                                            {notifications.length ? notifications.map((notification, i) => (
                                                                <li className="follow-up-notification" key={i}>
                                                                    <div className="follow-up-item">

                                                                        <p className="follow-up-text">{notification?.type == 54 ? `You have ${notification?.body} follow ups for today.` : `You have a follow up with ${notification?.userId?.firstName + ' ' + notification?.userId?.lastName} at ${dateFormat(notification.body, "h:MM TT")} today.`} </p>

                                                                    </div>
                                                                    <div className="follow-up-notification-date justify-content-end">
                                                                        {/* <button className="view-btn btn btn-primary">View</button> */}

                                                                        <Link onClick={() => this.markReadNotification(notification._id, notification.created)} className="view-btn btn btn-primary">View</Link>
                                                                        <small className="follow-up-time">{dateFormat(notification.created, "d mmmm yyyy h:MM TT")}</small>
                                                                    </div>
                                                                </li>
                                                            )): <p className="text-danger text-center">No Data Found!</p>}
                                                        </ul>
                                                    </InfiniteScroll>
                                                </>
                                                // <table className="table table-bordered ">
                                                //     <thead>
                                                //         <tr>
                                                //             <th scope="col">#</th>
                                                //             <th scope="col">Name</th>
                                                //             <th scope="col">Mobile No</th>
                                                //             <th scope="col">Email</th>
                                                //             <th scope="col">Date</th>
                                                //             <th scope="col">Notes </th>
                                                //         </tr>
                                                //     </thead>
                                                //     <tbody>
                                                //         {followups.map((followup, i) => (
                                                //             <tr key={i}>
                                                //                 <th scope="row">{(i + 1)}</th>
                                                //                 <td>{followup.users.firstName + " " + followup.users.lastName}</td>
                                                //                 <td>{followup.users.fullMobileNo}</td>
                                                //                 <td>{followup.users.email}</td>
                                                //                 <td>{dateFormat(followup.start, "d mmmm yyyy")}</td>
                                                //                 <td>{followup.desc}</td>
                                                //             </tr>
                                                //         ))}
                                                //     </tbody>
                                                // </table>
                                            }
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {
                    showReschedule &&
                    <Popup
                        showPopup={showReschedule}
                        oncloseModal={this.closeRescheduleModal}
                        title='Edit Followup'
                        onSubmitModal={this.editReschedule}
                    >
                        <AddFollowup
                            selectDate={this.selectDate}
                            selectedDate={followupdata.start}
                            validationError={validationError}
                            notes={followupdata.desc}
                            handleChange={this.notesChangeHandler}
                        />
                    </Popup>
                }

                <Popup
                    showPopup={viewFollowupPopup}
                    oncloseModal={this.closeModal}
                    title='View Followup'
                    reSchedule={true}
                    onOpenRescheduleModal={this.onOpenRescheduleModal}
                >
                    <ViewFollowup
                        handleChange={this.handleChange}
                        followupdata={followupdata}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    followupsState: state.followupsState
});

const mapDispatchToProps = dispatch => ({
    getFollowupsByAdmin: (params) => dispatch(getFollowupsByAdminAction(params)),
    editFollowup: (params) => dispatch(editDietitianUserFollowupAction(params)),
    getFollowupNotificationsAction: (params) => dispatch(getFollowupNotificationsAction(params))
})
export default connect(mapStateToProps, mapDispatchToProps)(Followups);
