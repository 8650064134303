import React from 'react';
import Select from 'react-select'
const BulkMessage =({users, selectUsers, handleMessageChange, message, validationError })=> {
                return (
                   <div> 
                        {validationError && <div className="alert alert-danger" role="alert">{validationError}</div>}
                        <div className="form-group">
                            <label>Category</label>
                            <Select
                                isSearchable
                                isMulti
                                options={users}
                                placeholder="Select users"
                                onChange={(val) => selectUsers(val)}
                                className="mt-1 mb-1"
                            />
                        </div>
                        <div className="form-group">
                            <label>Note:</label>
                            <textarea className="form-control" rows="3" onChange={handleMessageChange} value={message}></textarea>
                        </div>
                    </div>
               
        
                )
}

export default BulkMessage;
