import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { connect } from 'react-redux'
import { getAllDietHistoryAction } from '../store/actions/dietHistory'

import DietHistoryHeader from '../components/dietHistory/DietHistoryHeader';
import DietHistoryBody from '../components/dietHistory/DietHistoryBody';
import Header from '../components/template/Header';

import { selectHistoryAction } from '../store/actions/dietHistory'
import { Loader } from '../components/Reuse';


class DietHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    selectHistoryHandler = (dietPlanHistoryId) =>{
        const { selectHistory } = this.props
        const { match: {params: { id: dietOfUserId}} } = this.props
        const params = {
            params: {
                dietOfUserId,
                dietPlanHistoryId
            }
        }
        selectHistory(params)
    }

    componentDidMount() {
        const { getDietHistory,  selectHistory } = this.props
        const { match: {params: { id: dietOfUserId}} } = this.props
        const params = {
            params: {
                dietOfUserId
            }
        }
        getDietHistory(params).then((historyData)=>{
            if(historyData &&
                historyData.res && 
                historyData.res.data && 
                historyData.res.data.responseData && 
                historyData.res.data.responseData.dietHistory
                ){
                const [ history ]  = historyData.res.data.responseData.dietHistory
                    if(history && history._id){
                        const params = {
                            params: {
                                dietOfUserId,
                                dietPlanHistoryId: history._id
                            }
                        }
                        selectHistory(params)
                    }
                  
                }
        })
    }

    makeActive = (currenId) =>{
        const {dietHistoryState: {  selectedHistory } } = this.props
        return (selectedHistory && selectedHistory._id === currenId) ? "widget_history active" : "widget_history "
    }

    render() {
        const { loader, dietHistoryState: { dietHistory, selectedHistory } } = this.props
        const { match: {params: { id: dietOfUserId}} } = this.props 
     
        return (
            <React.Fragment>
                {loader && <Loader />}
                
                <div className="page_wrapper">
                    <DietHistoryHeader 
                    dietOfUserId= {dietOfUserId}
                    />

                    <div className="users_list_wrapper">
                        {
                            dietHistory && dietHistory.length > 0
                        ?(

                        <div className="row">
                            <div className="col-xl-3 col-lg-4">
                                <div className="card_wrapper cm_window_hieght">
                                    <div className="user_card_detail">
                                        <h3>History</h3>
                                            <div className="widget_history_heading">
                                                <span className="widget_heading">Diet Plan</span>
                                                <span className="widget_heading">Created On </span>
                                            </div>
                                            {
                                             dietHistory.map((history)=>{
                                                return <div className={this.makeActive(history.value)} onClick={ () =>this.selectHistoryHandler(history.value) }>
                                                    <span className="widget_label">{history.label} calorie/day</span>
                                                    <span className="widget_data">{dateFormat(history.updated, "d mmmm yyyy")}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                     
                            <div className="col-xl-9 col-lg-8">
                                    <div className="card_wrapper cm_window_hieght pt-3">
                                    <div className="row mb-4">
                                            <div className="col">
                                                <h2>Diet Plan</h2>
                                                <h6>Total calorie target</h6>
                                            </div>
                                    </div>
                                    {
                                        selectedHistory && <DietHistoryBody dietPlanDetails={selectedHistory} />
                                    }
                                    </div>
                            </div>
                             </div>
                        
                        )
                        :
                        <div className="row">
                            <div className="card_wrapper cm_window_hieght">
                                <h6>No History data</h6>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    dietHistoryState: state.dietHistoryState
});

const mapDispatchToProps = dispatch => ({
    getDietHistory : (params) => dispatch(getAllDietHistoryAction(params)),
    selectHistory: (params) => dispatch(selectHistoryAction(params))
})
export default connect(mapStateToProps,
mapDispatchToProps,
)(DietHistory);
