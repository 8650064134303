import React, { useEffect, useState } from 'react'
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { notificationViewed } from '../../config/service/notificationService';
import InfiniteScroll from "react-infinite-scroll-component";
import { getNotifications } from '../../config/service/notificationService';

const UserLogin = ({ data, totalNotifications, fetchMoreDatas, hasMores }) => {
    const [notifications, setNotifications] = React.useState([]);
    const [totalNotification, setTotalNotification] = useState(null)
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    // const notifications = data?.length ? data?.filter(notification => notification.type == 51) : [];
    const fetchData = () => {
        const params = { 
            params: { 
                pageNo: pageNo,
                count: 10,
                notificationType: 51
            } 
    }
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications([]);
                    setNotifications(res?.data?.responseData?.result);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
    }

    const fetchMoreData = () => {
        let pageNoLocal = pageNo + 1;
        const params = { 
            params: { 
                pageNo: pageNoLocal,
                count: 10,
                notificationType: 51
            } 
    }
        
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications(currentArray => [...currentArray, ...res?.data?.responseData?.result]);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setPageNo(pageNoLocal);
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
        
    };
    useEffect(() => {
        fetchData();
    }, []);

    const markReadNotification = (notificationId) => {
        let param = {
            id: notificationId,
            status: 1  //0= Unread, 1=Read , 2=All
        }
        notificationViewed(param)
            .then(res => {

                if (res.data.statusCode == 0) {
                    console.log("notification error====================");
                }
            })
    }

    // console.log("user login", notifications)

    return (
        <div className="card_wrapper">
            <ul className="list-unstyled notification-wrapper">
                <InfiniteScroll
                    dataLength={notifications ? notifications.length : 1}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                >
                    {notifications.length ? notifications?.map((notification, index) => {
                        return <li className="notification-messages" key={index}>
                            <div className="notification-msg-wrapper">
                                <div className="notification-item">
                                    <p className="msg"> <b>{`${notification.userId.firstName}  ${notification.userId.lastName}`}</b> has logged in</p>
                                </div>
                                <div className="notification-date-time justify-content-end">
                                    <Link  to={'/user/' + notification.userId._id} className="view-btn btn btn-primary">View</Link>
                                    <small className="notification-time">{dateFormat(notification.created, "ddd, d mmm yyyy h:MM TT")}</small>
                                </div>
                            </div>
                        </li>
                    }): <p className="text-danger text-center">No Data Found!</p>}
                </InfiniteScroll>
            </ul>
        </div>
    )
}

export default UserLogin