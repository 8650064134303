import { getAllHistory, getDietPlanHistory } from '../service/dietHistoryService'

export const FETCH_DIET_HISTORY = 'FETCH_DIET_HISTORY'
export const SELECT_DIET_HISTORY = 'SELECT_DIET_HISTORY'

export const getAllDietHistoryAction = (params) => ({
  type: FETCH_DIET_HISTORY,
  promise: getAllHistory(params)
})

export const selectHistoryAction = (params) =>({
  type: SELECT_DIET_HISTORY,
  promise: getDietPlanHistory(params)
})