import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../Reuse';
import { login } from '../../config/service/adminService'
import axios from 'axios';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            emailErrorMsg: '',
            passwordErrorMsg: '',
            errors: '',
            loader: false,
            passShow: false
        }
    }

    showPass = () => this.setState({ passShow: !this.state.passShow })


    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.setState({ errors: '' }))
    }

    handleValidetion = () => {
        let validate = true;
        let validateEmail = this.state.email;
        let validatePassword = this.state.password;

        var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
        let validEmail = new RegExp(pattern).test(validateEmail);


        if (validateEmail === '' || validateEmail === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Email cannot be empty.'})
        }else if(!validEmail){
            validate = false;
            this.setState({emailErrorMsg: 'Enter valid email.'})
        } else{
            this.setState({emailErrorMsg: ''})
        }

        if (validatePassword === '' || validatePassword === undefined) {
            validate = false;
            this.setState({passwordErrorMsg: 'Password cannot be empty'})
        }else if(validatePassword.length<5){
            validate = false;
            this.setState({passwordErrorMsg: 'Password should contain atleast 5 characters'})
        } else {
            this.setState({passwordErrorMsg: ''})
        }

        return validate
    }

    handleSubmit = e => {
        e.preventDefault();

        if (this.handleValidetion()) {
            this.setState({ loader: true });
            let obj = {
                'email': this.state.email,
                'password': this.state.password,
                'role': 2 //role=2 for dietician
            }
            login(obj).then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    
                    localStorage.setItem('accessToken', res.data.responseData.accessToken);
                    localStorage.setItem('userId', res.data.responseData.adminProfile._id);
                    
                    let accessToken = localStorage.getItem('accessToken')
                    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
                    axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;

                    // this.props.history.push({
                    //     pathname: '/',
                    //     state: { details: res.data.responseData.adminProfile }
                    //   })

                    this.props.history.push('/users/1');
                } else {
                    this.setState({ errors: res.data.error.errorMessage, loader: false })
                }
            })

        }
    }


    render() {

        let { email, password, loader, emailErrorMsg, passwordErrorMsg, errors, passShow } = this.state;

        return (
            <div className="login_wrapper">

                {loader && <Loader />}

                <div className="row h-100 no-gutters">


                    <div className="col-lg-4 h-100 login_left" style={{ backgroundImage: `url(${require('../../assets/img/fitPlusBackground.jpg')})` }}>
                        <div className="row no-gutters h-100 align-items-center">
                            <div className="col">
                                <img className="cm_logo" src={require('../../assets/img/logo.svg')} alt="" />
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-8 h-100 login_right">
                        <div className="row no-gutters h-100 align-items-center">
                            <div className="col">


                                <form onSubmit={this.handleSubmit}>
                                    <h3>Login</h3>

                                    {
                                        errors && <h4 className="cm_erros">{errors}</h4>
                                    }
                                    <br />
                                    <br />
                                    <div className="form-group">
                                        <input
                                            className={`form-control ${emailErrorMsg ? 'input_error' : ''}`}
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                            autoComplete="off" />

                                        {emailErrorMsg && <span className="error_msg">{emailErrorMsg}</span>}
                                    </div>

                                    <div className="form-group">
                                        <input
                                            className={`form-control ${passwordErrorMsg ? 'input_error' : ''}`}
                                            type={passShow ? 'text' : 'password'}
                                            placeholder="Password"
                                            name="password"
                                            value={password}
                                            onChange={this.handleChange} />

                                        <span className="cm_eye" onClick={this.showPass}><i className={`fa fa-eye${passShow ? '' : '-slash'}`}></i></span>

                                        {passwordErrorMsg && <span className="error_msg">{passwordErrorMsg}</span>}
                                    </div>

                                    <div className="login_actions">
                                        <Link to="/forgot-password" className="login_link_forgot">Forgot Password ?</Link>
                                        <button type="submit" className="btn btn-primary btn-elevate">Login</button>
                                    </div>

                                </form>


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}


export default Login;
