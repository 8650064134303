import setting from './settings'


export default (() => {
  return {
    'AUTH': 'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy',
    'ADMIN_DIETICIAN_LOGIN': setting.api.url + "admin/dieticianLogin",
    'ADMIN_DIETICIAN_LOGOUT': setting.api.url + "user/logout",
    'SEND_OTP': setting.api.url + "user/sendOtpV2",

    'CHECK_SESSION': setting.api.url + "admin/checkSession",
    //'ADMIN_GET_USERS': setting.api.url + "admin/getAppUsers",
    'ADMIN_GET_USERS': setting.api.url + "admin/getDieticianUsers",
    'GET_ALL_DIETICIANS': setting.api.url + "admin/getAllDieticians",
    'ASSIGN_DIETICIAN': setting.api.url + "admin/assignDietician",
    'ADMIN_GET_USERS_LABELS': setting.api.url + "admin/getDieticianUsersLabels",
    'GET_ALL_USERS': setting.api.url + "admin/getAppUsers",
    'UPDATE_ALLOTED_CALLS': setting.api.url + "admin/updateAllotedCalls",
    'EDIT_USER_PROFILE': setting.api.url + "admin/editUserProfile",

    'GET_USER_DETAILS': setting.api.url + "admin/viewUser",
    'FORGOT_PASSWORD': setting.api.url + "admin/forgotPassword",
    'ADD_CALL_TIME_SLOTS': setting.api.url + "admin/addCallTimeSlots",

    'GET_USER_ANSWERS': setting.api.url + "questions/getUserAnswersAdmin",
    'GET_QUESTION_BY_ID': setting.api.url + "questions/getQuestionById",
    'UPDATE_USER_ANSWER': setting.api.url + "questions/updateUserAnswer",
    'GET_DIETICIAN_QUESTIONS': setting.api.url + "questions/getDieticianQuestions",


    'GET_USER_DIET_PLAN': setting.api.url + "dietPlan/getDietPlanViaAdmin",
    'ADD_DIET_PLAN': setting.api.url + "dietPlan/addDietPlan",
    'EDIT_DIET_PLAN': setting.api.url + "dietPlan/editDietPlan",
    'DELETE_DIET_PLAN': setting.api.url + "dietPlan/deleteDietPlan",

    'GET_PREVIOUS_ASSIGN_PENDING_DIET_PLAN': setting.api.url + "dietPlanApprovePending/getApprovePendingOfUser",
    'CHANGE_DIET_PLAN': setting.api.url + "dietPlanApprovePending/changeDietPlan",
    'REVIEW_DIET_PLAN': setting.api.url + "dietPlanApprovePending/reviewDietPlan",

    'USER_DAILY_DIET': setting.api.url + "diet/userDietByAdmin",

    'GET_ALL_FOODS': setting.api.url + "food/getAllFoodsByDietician",

    'AVG_DIETICIAN_RATING': setting.api.url + "dieticianRating/getAvgDieticianRating",


    'GET_USER_FITNESS_PLAN': setting.api.url + "fitnessPlan/getUserFitnessplanAdmin",
    'ADD_FITNESS_PLAN': setting.api.url + "fitnessPlan/addFitnessPlan",
    'EDIT_FITNESS_PLAN': setting.api.url + "fitnessPlan/editFitnessPlan",
    'DELETE_FITNESS_PLAN': setting.api.url + "fitnessPlan/deleteFitnessPlan",

    'GET_FITNESS_TEMPELATE_LABELS': setting.api.url + "fitnessPlanTempelate/getFitnessTempelateLabels",
    'GET_FITNESS_TEMPELATE': setting.api.url + "fitnessPlanTempelate/getFitnessTempelate",
    'ADD_FITNESS_TEMPELATE': setting.api.url + "fitnessPlanTempelate/addFitnessPlanTempelate",

    'GET_EXERCISES': setting.api.url + "fitnessExercise/getFitnessExercises",
    'GET_EXERCISE_CATEGORIES': setting.api.url + "exerciseCategory/getExerciseCategories",
    'CHANGE_PASSWORD': setting.api.url + "admin/changePassword",
    'GET_DIETICIAN_PROFILE': setting.api.url + "admin/getDieticianProfile",
    'EDIT_DIETICIAN_PROFILE': setting.api.url + "admin/editDieticianProfile",

    'GET_USER_LOGS': setting.api.url + "userLogs/getUserNotes",
    'ADD_USER_LOGS': setting.api.url + "userLogs/addNote",


    'SAVE_DIET_PLAN_TEMPELATE': setting.api.url + "dietPlanTempelate/addDietPlanTempelate",
    'GET_DIET_TEMPELATE_LABELS': setting.api.url + "dietPlanTempelate/getDietTempelateLabels",
    'GET_DIET_PLAN_TEMPELATE': setting.api.url + "dietPlanTempelate/getDietPlanTemplate",

    'USER_STATS': setting.api.url + "user/userStats",
    'GET_USER_TARGET': setting.api.url + "target/getUserTargetByAdmin",
    'GET_SELECTED_TIME_SLOTS': setting.api.url + "user/getSelectedTimeSlots",

    'GET_USER_MESSAGES': setting.api.url + "messages/getMessages",
    'GET_FITNESS_MESSAGES': setting.api.url + "messages/getDietOrFitnessMessages",

    'GET_CALL_REQUESTS': setting.api.url + "callScheduling/getCallRequests",
    'CHANGE_REQUEST_STATUS': setting.api.url + "callScheduling/changeRequestStatus",

    'GET_ALL_TIME_SLOTS': setting.api.url + "timeSlot/getAllTimeSlots",
    'EDIT_TIME_SLOTS_BY_ADMIN': setting.api.url + "timeSlot/submitTimeSlotsViaAdmin",

    'GET_ALL_HISTORY_BY_ADMIN': setting.api.url + "dietPlanHistory/getDietPlanHistoryByAdmin",
    'GET_SINGLE_PLAN_HISTORY_BY_ADMIN': setting.api.url + "dietPlanHistory/getSinglePlanHistory",

    'ADD_DIETITIAN_FOLLOWUP_USER': setting.api.url + "followups/addFollowup",
    'GET_DIETITIAN_FOLLOWUP_USER': setting.api.url + "followups/getFollowupsByAdmin",
    'EDIT_DIETITIAN_FOLLOWUP_USER': setting.api.url + "followups/rescheduleFollowup",

    'GET_ALL_HELP_VIDEOS': setting.api.url + "helpVideos/getAllHelpVideos",
    'CHECK_UNREAD_MESSAGE': setting.api.url + "messages/checkUnreadMessageOfDietician",

    'SEND_EMAIL_TO_PDF_DIET_PLAN': setting.api.url + "dietPlan/sendEmailToPdfDietPlan",

    'GET_NOTIFICATION': setting.api.url + "dietician/notification",
    'GET_USER_PRODUCT_DETAILS': setting.api.url + "dietician/userProductDetail",
    'NOTIFICATION_COUNT': setting.api.url + "dietician/notificationCount",
    'NOTIFICATION_VIEWED': setting.api.url + "dietician/notificationViewed",
    'FOLLOWUP_NOTIFICATION_COUNT': setting.api.url + "followups/notificationCount",
    'FOLLOWUP_NOTIFICATION': setting.api.url + "followups/notification",
    'UPDATE_DIET_PLAN_END_DATE': setting.api.url + "dietPlan/setDietPlanEndDate",
    'NOTIFICATION_READ': setting.api.url + "dietician/allNotificationViewed",

    'GET_AVAILABLE_TIMESLOTS_OF_EXPERT': setting.api.urlV2 + "user/getAvailableTimeSlotsOfExpert",
    "GET_SERVICES": setting.api.url + "services/getServices",
    'GET_TRAINERS_BY_AVAILABLE_SLOT_V2': setting.api.url + "admin/getTrainersByAvailableSlotV2",
    'GET_USER_FREE_SESSION_LEFT': setting.api.url + "admin/getUserFreeSessionsLeft",
    'GET_USER_PAYMENT_DUE_SESSIONS': setting.api.url + "admin/getUserPaymentDueSession",
    'BOOK_TIME_SLOT_OF_EXPERT': setting.api.url + "callScheduling/scheduleVideoSessionBYAdmin",
    'SET_ANSWERED_QUESTION' : setting.api.url + "questions/setAnsweredQuestions"
  }
})()