import React from 'react';
const BMICalculator =({handleChange,userDetails, calculateBMI,resetBMI, calculatedData, validationErrors})=> {
                return (
                    
                <div>
                    {validationErrors && <div className="alert alert-danger" role="alert">{validationErrors}</div>}
                    <div className="form-group row">
                        <label htmlFor="height" className="col-sm-3 col-form-label">Height (cm)</label>
                            <div className="col-sm-9">
                            <input
                                id="height"
                                type="number"
                                className="form-control"
                                name="height"
                                value={userDetails.height}
                                min="50"
                                placeholder="Enter height in cm"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="weight" className="col-sm-3 col-form-label">Weight (kg)</label>
                        <div className="col-sm-9">
                        <input
                            id="weight"
                            type="number"
                            value={userDetails.weight}
                            className="form-control"
                            placeholder="Enter wight in KG"
                            name="weight"
                            min="20"
                            onChange={handleChange}
                        />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="age" className="col-sm-3 col-form-label">Age (Years)</label>
                        <div className="col-sm-9">
                    <input
                            id="age"
                            type="number"
                            value={userDetails.age}
                            className="form-control"
                            name="age"
                            min="10"
                            placeholder="Enter age in years"
                            onChange={handleChange}
                        />
                        </div>
                    </div>

                    <fieldset className="form-group">
                        <div className="row">
                        <legend className="col-form-label col-sm-3 pt-0">Gender</legend>
                        <div className="col-sm-9">
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="gender" id="Male" value="Male"  onChange={handleChange} />
                            <label className="form-check-label" htmlFor="Male">Male</label>
                            </div>
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="gender" id="Female" value="Female" onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="Female">Female</label>
                            </div>
                        </div>
                        </div>
                    </fieldset>

                    <div className="form-group row">
                        <label className="col-sm-3"></label>
                        <div className="col-sm-2"><button type="submit" className="btn btn-success" variant="success" onClick={calculateBMI} >Calculate</button></div>
                        <div className="col-sm-3"><button type="submit" className="btn btn-success" variant="success" onClick={resetBMI} >Reset</button></div>
                    </div>
                    {(calculatedData.bmr && calculatedData.bmi) && 
                        <div className="card">
                            <div className="card-body">
                                <h4><strong>BMI: </strong>{calculatedData.bmi}</h4>
                                <h4><strong>BMR: </strong>{calculatedData.bmr}</h4>
                            </div>
                        </div> }
                    </div>
           
    )
}

export default BMICalculator;
