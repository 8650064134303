export default {
  api: {
    url: 'https://devapi.fitplus.biz/fitstar/api/v1/',
    socket: 'https://devsocket.fitplus.biz',    
    mode: 'cors'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAhMbkezcOXFUdGpfT4Bp4W_d7ANXBCKVs",
    authDomain: "fitplus-dev.firebaseapp.com",
    databaseURL: "https://fitplus-dev.firebaseio.com",
    projectId: "fitplus-dev",
    storageBucket: "fitplus-dev.appspot.com",
    messagingSenderId: "164811617082",
    appId: "1:164811617082:web:01de61794ed89dfd6c1603",
    measurementId: "G-W9VM5DVR0E"
  }
}