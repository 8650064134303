import React, { Component } from 'react';
import axios from 'axios';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Login from './components/login/Login';
import ForgotPassword from './components/login/ForgotPassword';
import User from './components/users/User';
import Chat from './components/chat/Chat';
import CallSchedules from './components/callSchedules/CallSchedules';
import Profile from './components/profile/Profile';
import ChangePassword from './components/profile/ChangePassword';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import UsersContainner from './containers/Users'
import DietHistoryContainer from './containers/DietHistory'
import FollowupsContainer from './containers/Followups'
import HelpVideos from './containers/HelpVideos'
import Notification from './components/Notifications';
import Layout from './components/template/Layout';

const history = createBrowserHistory();


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('accessToken')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
)


const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !localStorage.getItem('accessToken')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
)



class App extends Component {
  render() {

    let accessToken = localStorage.getItem('accessToken')
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;
   
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <Switch>
          <PublicRoute exact path='/login' component={Login} />
          <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
          <PrivateRoute  path='/' component={Layout} />
          <Redirect exact from="/" to="/users/:pageNo?"  />
         
          {/* <PrivateRoute exact path='/allUsers' component={AllUsers} /> */}
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
    )
  }
}

export default App;