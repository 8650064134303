import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { addDietPlan, getAllFoods } from '../../config/service/usersService';
import { perDayCalories } from '../constants/calorieBreakdown';
import { calorieBreakDown } from './Calculations';
import cogoToast from 'cogo-toast';
import { addDays } from 'date-fns';

class AddDietPlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            morningSnacksDiet: [],
            breakfastDiet: [],
            midMorningDiet: [],
            lunchDiet: [],
            midEveningDiet: [],
            dinnerDiet: [],
            bedTimeDiet: [],

            morningSnacksOptions: [],
            breakfastOptions: [],
            midMorningOptions: [],
            lunchOptions: [],
            midEveningOptions: [],
            dinnerOptions: [],
            bedTimeOptions: [],

            morningSnacksDietTime: "00:00",
            breakfastDietTime: "00:00",
            midMorningDietTime: "00:00",
            lunchDietTime: "00:00",
            midEveningDietTime: "00:00",
            dinnerDietTime: "00:00",
            bedTimeDietTime: "00:00",

            recommendedMorningSnacksCalories: 0,
            recommendedBreakfastCalories: 0,
            recommendedMidMorningCalories: 0,
            recommendedLunchCalories: 0,
            recommendedMidEveningCalories: 0,
            recommendedDinnerCalories: 0,
            recommendedBedTimeCalories: 0,
            perDayCalories: 0,

            morningSnacksDietCalorie: 0,
            midMorningDietCalorie: 0,
            midEveningDietCalorie: 0,
            bedTimeDietCalorie: 0,

            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            morningSnacksArray: [""],
            breakfastArray: [""],
            lunchArray: [""],
            dinnerArray: [""],
            midMorningArray: [""],
            midEveningArray: [""],
            bedTimeArray: [""],

            typingTimeout: 0,
            foodItems: [],
        }
    }


    validFromChange = date => this.setState({ validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)) });
    validToChange = date => this.setState({ validTo: new Date(new Date(date).setHours(23, 59, 59, 0)) });

    removeOption = (fieldName, index) => {
        let { morningSnacksOptions, breakfastOptions, midMorningOptions, lunchOptions, midEveningOptions, dinnerOptions, bedTimeOptions } = this.state;

        switch (fieldName) {
            case 'morningSnack':
                morningSnacksOptions.splice(index, 1);
                this.setState({ morningSnacksOptions })
                break;
            case 'breakfast':
                breakfastOptions.splice(index, 1);
                this.setState({ breakfastOptions })
                break;
            case 'midMorning':
                midMorningOptions.splice(index, 1);
                this.setState({ midMorningOptions })
                break;
            case 'lunch':
                lunchOptions.splice(index, 1);
                this.setState({ lunchOptions })
                break;
            case 'midEvening':
                midEveningOptions.splice(index, 1);
                this.setState({ midEveningOptions })
                break;
            case 'dinner':
                dinnerOptions.splice(index, 1);
                this.setState({ dinnerOptions })
                break;
            case 'bedTime':
                bedTimeOptions.splice(index, 1);
                this.setState({ bedTimeOptions })
                break;
        }
    }

    addMoreOption = (fieldName, index) => {
        const { morningSnacksOptions, breakfastOptions, midMorningOptions, lunchOptions, midEveningOptions, dinnerOptions, bedTimeOptions } = this.state

        switch (fieldName) {
            case "morningSnack":
                morningSnacksOptions.push("");
                this.setState({ morningSnacksOptions })
                break;

            case "breakfast":
                breakfastOptions.push("");
                this.setState({ breakfastOptions })
                break;

            case "midMorning":
                midMorningOptions.push("");
                this.setState({ midMorningOptions })
                break;

            case "lunch":
                lunchOptions.push("");
                this.setState({ lunchOptions })
                break;

            case "midEvening":
                midEveningOptions.push("");
                this.setState({ midEveningOptions })
                break;

            case "dinner":
                dinnerOptions.push("");
                this.setState({ dinnerOptions })
                break;

            case "bedTime":
                bedTimeOptions.push("");
                this.setState({ bedTimeOptions })
                break;

            default: break;
        }
    }

    onFoodChange = (event, data, index, optionsIndex) => {
        switch (event) {
            case "morningSnack":
                const morningSnacksOptions = Array.from(this.state.morningSnacksOptions);
                morningSnacksOptions[optionsIndex].morningSnacksDiet[index] = {
                    ...this.state.morningSnacksDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };
                this.setState({ morningSnacksOptions }, () => this.calculateCaloriesAssigned('morningSnack', optionsIndex));
                break;

            case 'midMorning':
                const midMorningOptions = Array.from(this.state.midMorningOptions);

                midMorningOptions[optionsIndex].midMorningDiet[index] = {
                    ...this.state.midMorningDiet[index], foodId: data.value, calorieContent: data.calorie,
                    unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ midMorningOptions }, () => this.calculateCaloriesAssigned('midMorning', optionsIndex));
                break;

            case "breakfast":
                // const breakfastDiet = Array.from(this.state.breakfastDiet);
                const breakfastOptions = Array.from(this.state.breakfastOptions);
                breakfastOptions[optionsIndex].breakfastDiet[index] = {
                    ...this.state.breakfastDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ breakfastOptions }, () => this.calculateCaloriesAssigned('breakfast', optionsIndex));
                break;


            case "lunch":
                // const lunchDiet = Array.from(this.state.lunchDiet);
                const lunchOptions = Array.from(this.state.lunchOptions);
                lunchOptions[optionsIndex].lunchDiet[index] = {
                    ...this.state.lunchDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ lunchOptions }, () => this.calculateCaloriesAssigned('lunch', optionsIndex));
                break;

            case "midEvening":
                // const midEveningDiet = Array.from(this.state.midEveningDiet);
                const midEveningOptions = Array.from(this.state.midEveningOptions);
                midEveningOptions[optionsIndex].midEveningDiet[index] = {
                    ...this.state.midEveningDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ midEveningOptions }, () => this.calculateCaloriesAssigned('midEvening', optionsIndex));
                break;


            case "dinner":
                // const dinnerDiet = Array.from(this.state.dinnerDiet);
                const dinnerOptions = Array.from(this.state.dinnerOptions);

                dinnerOptions[optionsIndex].dinnerDiet[index] = {
                    ...this.state.dinnerDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ dinnerOptions }, () => this.calculateCaloriesAssigned('dinner', optionsIndex));
                break;


            case "bedTime":
                // const bedTimeDiet = Array.from(this.state.bedTimeDiet);
                const bedTimeOptions = Array.from(this.state.bedTimeOptions);
                bedTimeOptions[optionsIndex].bedTimeDiet[index] = {
                    ...this.state.bedTimeDiet[index], foodId: data.value, calorieContent: data.calorie, unit: data.foodUnit, label: data.label, protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    quantity: 1
                };
                this.setState({ bedTimeOptions }, () => this.calculateCaloriesAssigned('bedTime', optionsIndex));
                break;

        }
    }

    onUnitChange = (event, data, index) => {

        switch (event) {
            case "morningSnack":
                const morningSnacksDiet = Array.from(this.state.morningSnacksDiet);
                morningSnacksDiet[index] = { ...this.state.morningSnacksDiet[index], unit: data.target.value };
                this.setState({ morningSnacksDiet })

                break;

            case "breakfast":
                const breakfastDiet = Array.from(this.state.breakfastDiet);
                breakfastDiet[index] = { ...this.state.breakfastDiet[index], unit: data.target.value };
                this.setState({ breakfastDiet });
                break;

            case "midMorning":
                const midMorningDiet = Array.from(this.state.midMorningDiet);
                midMorningDiet[index] = { ...this.state.midMorningDiet[index], unit: data.target.value };
                this.setState({ midMorningDiet });
                break;

            case "lunch":
                const lunchDiet = Array.from(this.state.lunchDiet);
                lunchDiet[index] = { ...this.state.lunchDiet[index], unit: data.target.value };
                this.setState({ lunchDiet });
                break;

            case "midEvening":
                const midEveningDiet = Array.from(this.state.midEveningDiet);
                midEveningDiet[index] = { ...this.state.midEveningDiet[index], unit: data.target.value };
                this.setState({ midEveningDiet });
                break;

            case "dinner":
                const dinnerDiet = Array.from(this.state.dinnerDiet);
                dinnerDiet[index] = { ...this.state.dinnerDiet[index], unit: data.target.value };
                this.setState({ dinnerDiet });
                break;

            case "bedTime":
                const bedTimeDiet = Array.from(this.state.bedTimeDiet);
                bedTimeDiet[index] = { ...this.state.bedTimeDiet[index], unit: data.target.value };
                this.setState({ bedTimeDiet });
                break;
        }

    }

    onQtyChange = (event, data, index, optionsIndex) => {
        
        switch (event) {
            case "morningSnack":
                const morningSnacksOptions = Array.from(this.state.morningSnacksOptions);

                morningSnacksOptions[optionsIndex].morningSnacksDiet[index] = { ...this.state.morningSnacksOptions[optionsIndex].morningSnacksDiet[index], quantity: data.target.value };
                this.setState({ morningSnacksOptions }, () => this.calculateCaloriesAssigned('morningSnack', optionsIndex))
                break;

            case "breakfast":
                const breakfastOptions = Array.from(this.state.breakfastOptions);
                breakfastOptions[optionsIndex].breakfastDiet[index] = { ...this.state.breakfastOptions[optionsIndex].breakfastDiet[index], quantity: data.target.value };
                this.setState({ breakfastOptions }, () => this.calculateCaloriesAssigned('breakfast', optionsIndex));
                break;


            case "midMorning":
                const midMorningOptions = Array.from(this.state.midMorningOptions);
                midMorningOptions[optionsIndex].midMorningDiet[index] = { ...this.state.midMorningOptions[optionsIndex].midMorningDiet[index], quantity: data.target.value };
                this.setState({ midMorningOptions }, () => this.calculateCaloriesAssigned('midMorning', optionsIndex));
                break;

            case "lunch":
                const lunchOptions = Array.from(this.state.lunchOptions);
                lunchOptions[optionsIndex].lunchDiet[index] = { ...this.state.lunchOptions[optionsIndex].lunchDiet[index], quantity: data.target.value };
                this.setState({ lunchOptions }, () => this.calculateCaloriesAssigned('lunch', optionsIndex));
                break;

            case "midEvening":
                const midEveningOptions = Array.from(this.state.midEveningOptions);
                midEveningOptions[optionsIndex].midEveningDiet[index] = { ...this.state.midEveningOptions[optionsIndex].midEveningDiet[index], quantity: data.target.value };
                this.setState({ midEveningOptions }, () => this.calculateCaloriesAssigned('midEvening', optionsIndex));
                break;

            case "dinner":
                const dinnerOptions = Array.from(this.state.dinnerOptions);
                dinnerOptions[optionsIndex].dinnerDiet[index] = { ...this.state.dinnerOptions[optionsIndex].dinnerDiet[index], quantity: data.target.value };
                this.setState({ dinnerOptions }, () => this.calculateCaloriesAssigned('dinner', optionsIndex));
                break;


            case "bedTime":
                const bedTimeOptions = Array.from(this.state.bedTimeOptions);
                bedTimeOptions[optionsIndex].bedTimeDiet[index] = { ...this.state.bedTimeOptions[optionsIndex].bedTimeDiet[index], quantity: data.target.value };
                this.setState({ bedTimeOptions }, () => this.calculateCaloriesAssigned('bedTime', optionsIndex));
                break;
        }
    }

    handleOnChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    addMoreFood = (fieldName, e, optionsIndex) => {
        switch (fieldName) {
            case "morningSnack":
                let morningSnacksOptions = Array.from(this.state.morningSnacksOptions)

                morningSnacksOptions[optionsIndex].morningSnacksDiet ? morningSnacksOptions[optionsIndex].morningSnacksDiet.push({}) :
                    morningSnacksOptions[optionsIndex] = { morningSnacksDiet: [{}] }
                // morningSnacksDiet.push("");
                this.setState({ morningSnacksOptions })
                break;

            case "breakfast":
                let breakfastOptions = Array.from(this.state.breakfastOptions)
                breakfastOptions[optionsIndex].breakfastDiet ? breakfastOptions[optionsIndex].breakfastDiet.push({}) :
                    breakfastOptions[optionsIndex] = { breakfastDiet: [{}] }
                // breakfastDiet.push("");
                this.setState({ breakfastOptions })
                break;

            case "midMorning":
                let midMorningOptions = Array.from(this.state.midMorningOptions)
                midMorningOptions[optionsIndex].midMorningDiet ? midMorningOptions[optionsIndex].midMorningDiet.push({}) :
                    midMorningOptions[optionsIndex] = { midMorningDiet: [{}] }

                // midMorningDiet.push("");
                this.setState({ midMorningOptions })
                break;

            case "lunch":
                let lunchOptions = Array.from(this.state.lunchOptions)
                lunchOptions[optionsIndex].lunchDiet ? lunchOptions[optionsIndex].lunchDiet.push({}) :
                    lunchOptions[optionsIndex] = { lunchDiet: [{}] }

                // lunchDiet.push("");
                this.setState({ lunchOptions })
                break;

            case "midEvening":

                let midEveningOptions = Array.from(this.state.midEveningOptions)
                midEveningOptions[optionsIndex].midEveningDiet ? midEveningOptions[optionsIndex].midEveningDiet.push({}) :
                    midEveningOptions[optionsIndex] = { midEveningDiet: [{}] }

                // midEveningDiet.push("");
                this.setState({ midEveningOptions })
                break;

            case "dinner":
                let dinnerOptions = Array.from(this.state.dinnerOptions)
                dinnerOptions[optionsIndex].dinnerDiet ? dinnerOptions[optionsIndex].dinnerDiet.push({}) :
                    dinnerOptions[optionsIndex] = { dinnerDiet: [{}] }
                // dinnerDiet.push("");
                this.setState({ dinnerOptions })
                break;

            case "bedTime":
                let bedTimeOptions = Array.from(this.state.bedTimeOptions)
                bedTimeOptions[optionsIndex].bedTimeDiet ? bedTimeOptions[optionsIndex].bedTimeDiet.push({}) :
                    bedTimeOptions[optionsIndex] = { bedTimeDiet: [{}] }
                // bedTimeDiet.push("");
                this.setState({ bedTimeOptions })
                break;

            default: break;
        }
    }

    calculateCaloriesAssigned = (fieldName, optionsIndex) => {
        const { morningSnacksOptions, midMorningOptions, breakfastOptions, lunchOptions, midEveningOptions, dinnerOptions, bedTimeOptions } = this.state
        switch (fieldName) {
            case "morningSnack":
                let morningSnacksDietCalorie = 0;
                let morningSnacksDietProtein = 0;
                let morningSnacksDietCarbs = 0;
                let morningSnacksDietFat = 0;
                let morningSnacksDietFibre = 0;

                morningSnacksOptions[optionsIndex].morningSnacksDiet.map(food => {
                    if (food.foodId) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;       
                        morningSnacksDietCalorie = morningSnacksDietCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        morningSnacksDietProtein = morningSnacksDietProtein + ((protein ? protein : 0) * quantity);
                        morningSnacksDietCarbs = morningSnacksDietCarbs + ((carb ? carb : 0) * quantity);
                        morningSnacksDietFat = morningSnacksDietFat + ((fat ? fat : 0) * quantity);
                        morningSnacksDietFibre = morningSnacksDietFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })
                morningSnacksOptions[optionsIndex].morningSnacksDietCalorie = morningSnacksDietCalorie.toFixed(1);
                morningSnacksOptions[optionsIndex].morningSnacksDietProtein = morningSnacksDietProtein.toFixed(1);
                morningSnacksOptions[optionsIndex].morningSnacksDietCarbs = morningSnacksDietCarbs.toFixed(1);
                morningSnacksOptions[optionsIndex].morningSnacksDietFat = morningSnacksDietFat.toFixed(1);
                morningSnacksOptions[optionsIndex].morningSnacksDietFibre = morningSnacksDietFibre.toFixed(1);

                this.setState({ morningSnacksOptions })
                break;

            case "breakfast":
                let breakfastDietCalorie = 0;
                let breakfastDietProtein = 0;
                let breakfastDietCarbs = 0;
                let breakfastDietFat = 0;
                let breakfastDietFibre = 0;

                breakfastOptions[optionsIndex].breakfastDiet.map(food => {
                    if (food.foodId) {
                        breakfastDietCalorie = breakfastDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        breakfastDietProtein = breakfastDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        breakfastDietCarbs = breakfastDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        breakfastDietFat = breakfastDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        breakfastDietFibre = breakfastDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })

                breakfastOptions[optionsIndex].breakfastDietCalorie = breakfastDietCalorie.toFixed(1);
                breakfastOptions[optionsIndex].breakfastDietProtein = breakfastDietProtein.toFixed(1);
                breakfastOptions[optionsIndex].breakfastDietCarbs = breakfastDietCarbs.toFixed(1);
                breakfastOptions[optionsIndex].breakfastDietFat = breakfastDietFat.toFixed(1);
                breakfastOptions[optionsIndex].breakfastDietFibre = breakfastDietFibre.toFixed(1);

                this.setState({ breakfastOptions })
                break;

            case "midMorning":
                let midMorningDietCalorie = 0;
                let midMorningDietProtein = 0;
                let midMorningDietCarbs = 0;
                let midMorningDietFat = 0;
                let midMorningDietFibre = 0;

                midMorningOptions[optionsIndex].midMorningDiet.map(food => {
                    if (food.foodId) {
                        midMorningDietCalorie = midMorningDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        midMorningDietProtein = midMorningDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        midMorningDietCarbs = midMorningDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        midMorningDietFat = midMorningDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        midMorningDietFibre = midMorningDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })
                midMorningOptions[optionsIndex].midMorningDietCalorie = midMorningDietCalorie.toFixed(1);
                midMorningOptions[optionsIndex].midMorningDietProtein = midMorningDietProtein.toFixed(1);
                midMorningOptions[optionsIndex].midMorningDietCarbs = midMorningDietCarbs.toFixed(1);
                midMorningOptions[optionsIndex].midMorningDietFat = midMorningDietFat.toFixed(1);
                midMorningOptions[optionsIndex].midMorningDietFibre = midMorningDietFibre.toFixed(1);

                this.setState({ midMorningOptions })
                break;

            case "lunch":
                let lunchDietCalorie = 0;
                let lunchDietProtein = 0;
                let lunchDietCarbs = 0;
                let lunchDietFat = 0;
                let lunchDietFibre = 0;

                lunchOptions[optionsIndex].lunchDiet.map(food => {
                    if (food.foodId) {
                        lunchDietCalorie = lunchDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        lunchDietProtein = lunchDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        lunchDietCarbs = lunchDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        lunchDietFat = lunchDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        lunchDietFibre = lunchDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })

                lunchOptions[optionsIndex].lunchDietCalorie = lunchDietCalorie.toFixed(1);
                lunchOptions[optionsIndex].lunchDietProtein = lunchDietProtein.toFixed(1);
                lunchOptions[optionsIndex].lunchDietCarbs = lunchDietCarbs.toFixed(1);
                lunchOptions[optionsIndex].lunchDietFat = lunchDietFat.toFixed(1);
                lunchOptions[optionsIndex].lunchDietFibre = lunchDietFibre.toFixed(1);

                this.setState({ lunchOptions })
                break;


            case "midEvening":
                let midEveningDietCalorie = 0;
                let midEveningDietProtein = 0;
                let midEveningDietCarbs = 0;
                let midEveningDietFat = 0;
                let midEveningDietFibre = 0;

                midEveningOptions[optionsIndex].midEveningDiet.map(food => {
                    if (food.foodId) {
                        midEveningDietCalorie = midEveningDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        midEveningDietProtein = midEveningDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        midEveningDietCarbs = midEveningDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        midEveningDietFat = midEveningDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        midEveningDietFibre = midEveningDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })

                midEveningOptions[optionsIndex].midEveningDietCalorie = midEveningDietCalorie.toFixed(1);
                midEveningOptions[optionsIndex].midEveningDietProtein = midEveningDietProtein.toFixed(1);
                midEveningOptions[optionsIndex].midEveningDietCarbs = midEveningDietCarbs.toFixed(1);
                midEveningOptions[optionsIndex].midEveningDietFat = midEveningDietFat.toFixed(1);
                midEveningOptions[optionsIndex].midEveningDietFibre = midEveningDietFibre.toFixed(1);

                this.setState({ midEveningOptions })
                break;

            case "dinner":
                let dinnerDietCalorie = 0;
                let dinnerDietProtein = 0;
                let dinnerDietCarbs = 0;
                let dinnerDietFat = 0;
                let dinnerDietFibre = 0;

                dinnerOptions[optionsIndex].dinnerDiet.map(food => {
                    if (food.foodId) {
                        dinnerDietCalorie = dinnerDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        dinnerDietProtein = dinnerDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        dinnerDietCarbs = dinnerDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        dinnerDietFat = dinnerDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        dinnerDietFibre = dinnerDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })

                dinnerOptions[optionsIndex].dinnerDietCalorie = dinnerDietCalorie.toFixed(1);
                dinnerOptions[optionsIndex].dinnerDietProtein = dinnerDietProtein.toFixed(1);
                dinnerOptions[optionsIndex].dinnerDietCarbs = dinnerDietCarbs.toFixed(1);
                dinnerOptions[optionsIndex].dinnerDietFat = dinnerDietFat.toFixed(1);
                dinnerOptions[optionsIndex].dinnerDietFibre = dinnerDietFibre.toFixed(1);

                this.setState({ dinnerOptions })
                break;


            case "bedTime":
                let bedTimeDietCalorie = 0;
                let bedTimeDietProtein = 0;
                let bedTimeDietCarbs = 0;
                let bedTimeDietFat = 0;
                let bedTimeDietFibre = 0;

                bedTimeOptions[optionsIndex].bedTimeDiet.map(food => {
                    if (food.foodId) {
                        bedTimeDietCalorie = bedTimeDietCalorie + ((food.calorieContent ? food.calorieContent : 0) * food.quantity);
                        bedTimeDietProtein = bedTimeDietProtein + ((food.protein ? food.protein : 0) * food.quantity);
                        bedTimeDietCarbs = bedTimeDietCarbs + ((food.carb ? food.carb : 0) * food.quantity);
                        bedTimeDietFat = bedTimeDietFat + ((food.fat ? food.fat : 0) * food.quantity);
                        bedTimeDietFibre = bedTimeDietFibre + ((food.fibre ? food.fibre : 0) * food.quantity);
                    }
                })

                bedTimeOptions[optionsIndex].bedTimeDietCalorie = bedTimeDietCalorie.toFixed(1);
                bedTimeOptions[optionsIndex].bedTimeDietProtein = bedTimeDietProtein.toFixed(1);
                bedTimeOptions[optionsIndex].bedTimeDietCarbs = bedTimeDietCarbs.toFixed(1);
                bedTimeOptions[optionsIndex].bedTimeDietFat = bedTimeDietFat.toFixed(1);
                bedTimeOptions[optionsIndex].bedTimeDietFibre = bedTimeDietFibre.toFixed(1);
                this.setState({ bedTimeOptions })
                break;

            default: break;

        }
    }

    submitAddDietPlan = (e) => {
        e.preventDefault();
        const {
            morningSnacksOptions,
            breakfastOptions,
            midMorningOptions,
            lunchOptions,
            midEveningOptions,
            dinnerOptions,
            bedTimeOptions,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            perDayCalories,
        } = this.state

        let status = 0; // for review

        let paramsObj = {
            dietOfUserId: this.props.userDetails._id,
            morningSnacksOptions,
            breakfastOptions,
            midMorningOptions,
            lunchOptions,
            midEveningOptions,
            dinnerOptions,
            bedTimeOptions,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            status
        };

        this.addDietPlanApi(paramsObj);

        // if (!morningSnacksDiet.length && !breakfastDiet.length && !lunchDiet.length && !dinnerDiet.length && !midMorningDiet.length
        //     && !midEveningDiet.length && !bedTimeDiet.length) {
        //     this.setState({ validationError: "Atleast one course meal is mandatory!" })
        // } else if (this.checkValidation()) {  //bcoz function return an array           
        //     this.setState({ validationError: "Fill all the details in a course." })
        // } else {
        //     this.setState({ validationError: "" })
        //     // this.addDietPlanApi(paramsObj);
        // }
    }

    checkValidation = () => {

        const { morningSnacksDiet, breakfastDiet, midMorningDiet, lunchDiet, midEveningDiet, dinnerDiet, bedTimeDiet, morningSnacksDietCalorie, breakfastDietCalorie, midMorningDietCalorie, lunchDietCalorie, midEveningDietCalorie,
            dinnerDietCalorie, bedTimeDietCalorie, } = this.state
        let notValid = false;

        if (morningSnacksDiet.length) {
            morningSnacksDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !morningSnacksDietCalorie)
                    notValid = true;
            })
        }
        if (breakfastDiet.length) {
            breakfastDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !breakfastDietCalorie)
                    notValid = true;
            })
        }
        if (midMorningDiet.length) {
            midMorningDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !midMorningDietCalorie)
                    notValid = true;
            })
        }
        if (lunchDiet.length) {
            lunchDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !lunchDietCalorie)
                    notValid = true;
            })
        }
        if (midEveningDiet.length) {
            midEveningDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !midEveningDietCalorie)
                    notValid = true;
            })
        }
        if (dinnerDiet.length) {
            dinnerDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !dinnerDietCalorie)
                    notValid = true;
            })
        }
        if (bedTimeDiet.length) {
            bedTimeDiet.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !bedTimeDietCalorie)
                    notValid = true;
            })
        }

        return notValid

    }

    addDietPlanApi = (params) => {
        let self = this;
        addDietPlan(params)
            .then(function (res) {                
                let response = res.data
                if (response.statusCode == 1) {
                    self.props.handleClose();
                    // self.props.getUserDietPlan();
                    cogoToast.success(response.responseData.message)

                } else if (response.statusCode == 0) {
                    self.setState({ validationError: response.error.errorMessage })
                }
            })
    }



    deleteFoodItem = (fieldName, index, e, optionsIndex) => {
        let { morningSnacksOptions, breakfastOptions, midMorningOptions, lunchOptions, midEveningOptions, dinnerOptions, bedTimeOptions } = this.state;

        let calorieDeducted;
        let proteinDeducted;
        let carbsDeducted;
        let fatDeducted;
        let fibreDeducted;

        switch (fieldName) {
            case "morningSnack":
                //to Delete the calories count of deleted food from list of selected food items for diet plan..
                if (morningSnacksOptions[optionsIndex].morningSnacksDiet[index]
                    && morningSnacksOptions[optionsIndex].morningSnacksDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = morningSnacksOptions[optionsIndex].morningSnacksDiet[index];

                    let { morningSnacksDietCalorie, morningSnacksDietProtein, morningSnacksDietCarbs,
                        morningSnacksDietFat, morningSnacksDietFibre } = morningSnacksOptions[optionsIndex]

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories to be deducted according to quantity

                    morningSnacksDietCalorie = morningSnacksDietCalorie - calorieDeducted;
                    morningSnacksDietProtein = morningSnacksDietProtein - proteinDeducted;
                    morningSnacksDietCarbs = morningSnacksDietCarbs - carbsDeducted;
                    morningSnacksDietFat = morningSnacksDietFat - fatDeducted;
                    morningSnacksDietFibre = morningSnacksDietFibre - fibreDeducted;

                    // deduction from total calories for Morning Snacks
                    morningSnacksOptions[optionsIndex].morningSnacksDietCalorie = morningSnacksDietCalorie.toFixed(1);
                    morningSnacksOptions[optionsIndex].morningSnacksDietProtein = morningSnacksDietProtein.toFixed(1);
                    morningSnacksOptions[optionsIndex].morningSnacksDietCarbs = morningSnacksDietCarbs.toFixed(1);
                    morningSnacksOptions[optionsIndex].morningSnacksDietFat = morningSnacksDietFat.toFixed(1);
                    morningSnacksOptions[optionsIndex].morningSnacksDietFibre = morningSnacksDietFibre.toFixed(1);
                }
                morningSnacksOptions[optionsIndex].morningSnacksDiet.splice(index, 1);// remove the array from listing
                this.setState({ morningSnacksOptions })
                break;

            case "breakfast":
                if (breakfastOptions[optionsIndex].breakfastDiet[index]
                    && breakfastOptions[optionsIndex].breakfastDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = breakfastOptions[optionsIndex].breakfastDiet[index];

                    let { breakfastDietCalorie, breakfastDietProtein, breakfastDietCarbs,
                        breakfastDietFat, breakfastDietFibre } = breakfastOptions[optionsIndex]

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);

                    //Total calories according to quantity
                    breakfastDietCalorie = breakfastDietCalorie - calorieDeducted;
                    breakfastDietProtein = breakfastDietProtein - proteinDeducted;
                    breakfastDietCarbs = breakfastDietCarbs - carbsDeducted;
                    breakfastDietFat = breakfastDietFat - fatDeducted;
                    breakfastDietFibre = breakfastDietFibre - fibreDeducted;

                    breakfastOptions[optionsIndex].breakfastDietCalorie = breakfastDietCalorie.toFixed(1);
                    breakfastOptions[optionsIndex].breakfastDietProtein = breakfastDietProtein.toFixed(1);
                    breakfastOptions[optionsIndex].breakfastDietCarbs = breakfastDietCarbs.toFixed(1);
                    breakfastOptions[optionsIndex].breakfastDietFat = breakfastDietFat.toFixed(1);
                    breakfastOptions[optionsIndex].breakfastDietFibre = breakfastDietFibre.toFixed(1);
                }

                breakfastOptions[optionsIndex].breakfastDiet.splice(index, 1);
                this.setState({ breakfastOptions })
                break;


            case "midMorning":
                if (midMorningOptions[optionsIndex].midMorningDiet[index]
                    && midMorningOptions[optionsIndex].midMorningDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = midMorningOptions[optionsIndex].midMorningDiet[index];

                    let { midMorningDietCalorie, midMorningDietProtein, midMorningDietCarbs,
                        midMorningDietFat, midMorningDietFibre } = midMorningOptions[optionsIndex]

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories according to quantity

                    midMorningDietCalorie = midMorningDietCalorie - calorieDeducted;
                    midMorningDietProtein = midMorningDietProtein - proteinDeducted;
                    midMorningDietCarbs = midMorningDietCarbs - carbsDeducted;
                    midMorningDietFat = midMorningDietFat - fatDeducted;
                    midMorningDietFibre = midMorningDietFibre - fibreDeducted;

                    midMorningOptions[optionsIndex].midMorningDietCalorie = midMorningDietCalorie.toFixed(1);
                    midMorningOptions[optionsIndex].midMorningDietProtein = midMorningDietProtein.toFixed(1);
                    midMorningOptions[optionsIndex].midMorningDietCarbs = midMorningDietCarbs.toFixed(1);
                    midMorningOptions[optionsIndex].midMorningDietFat = midMorningDietFat.toFixed(1);
                    midMorningOptions[optionsIndex].midMorningDietFibre = midMorningDietFibre.toFixed(1);

                }
                midMorningOptions[optionsIndex].midMorningDiet.splice(index, 1);
                this.setState({ midMorningOptions })
                break;


            case "lunch":
                if (lunchOptions[optionsIndex].lunchDiet[index]
                    && lunchOptions[optionsIndex].lunchDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = lunchOptions[optionsIndex].lunchDiet[index];

                    let { lunchDietCalorie, lunchDietProtein, lunchDietCarbs,
                        lunchDietFat, lunchDietFibre } = lunchOptions[optionsIndex]

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories according to quantity

                    lunchDietCalorie = lunchDietCalorie - calorieDeducted;
                    lunchDietProtein = lunchDietProtein - proteinDeducted;
                    lunchDietCarbs = lunchDietCarbs - carbsDeducted;
                    lunchDietFat = lunchDietFat - fatDeducted;
                    lunchDietFibre = lunchDietFibre - fibreDeducted;

                    lunchOptions[optionsIndex].lunchDietCalorie = lunchDietCalorie.toFixed(1);
                    lunchOptions[optionsIndex].lunchDietProtein = lunchDietProtein.toFixed(1);
                    lunchOptions[optionsIndex].lunchDietCarbs = lunchDietCarbs.toFixed(1);
                    lunchOptions[optionsIndex].lunchDietFat = lunchDietFat.toFixed(1);
                    lunchOptions[optionsIndex].lunchDietFibre = lunchDietFibre.toFixed(1);
                }
                lunchOptions[optionsIndex].lunchDiet.splice(index, 1);
                this.setState({ lunchOptions })
                break;


            case "midEvening":
                if (midEveningOptions[optionsIndex].midEveningDiet[index] &&
                    midEveningOptions[optionsIndex].midEveningDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = midEveningOptions[optionsIndex].midEveningDiet[index];

                    let { midEveningDietCalorie, midEveningDietProtein, midEveningDietCarbs,
                        midEveningDietFat, midEveningDietFibre } = midEveningOptions[optionsIndex];


                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories according to quantity

                    midEveningDietCalorie = midEveningDietCalorie - calorieDeducted;
                    midEveningDietProtein = midEveningDietProtein - proteinDeducted;
                    midEveningDietCarbs = midEveningDietCarbs - carbsDeducted;
                    midEveningDietFat = midEveningDietFat - fatDeducted;
                    midEveningDietFibre = midEveningDietFibre - fibreDeducted;

                    midEveningOptions[optionsIndex].midEveningDietCalorie = midEveningDietCalorie.toFixed(1);
                    midEveningOptions[optionsIndex].midEveningDietProtein = midEveningDietProtein.toFixed(1);
                    midEveningOptions[optionsIndex].midEveningDietCarbs = midEveningDietCarbs.toFixed(1);
                    midEveningOptions[optionsIndex].midEveningDietFat = midEveningDietFat.toFixed(1);
                    midEveningOptions[optionsIndex].midEveningDietFibre = midEveningDietFibre.toFixed(1);
                }

                midEveningOptions[optionsIndex].midEveningDiet.splice(index, 1);
                this.setState({ midEveningOptions })
                break;

            case "dinner":
                if (dinnerOptions[optionsIndex].dinnerDiet[index] &&
                    dinnerOptions[optionsIndex].dinnerDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = dinnerOptions[optionsIndex].dinnerDiet[index];

                    let { dinnerDietCalorie, dinnerDietProtein, dinnerDietCarbs,
                        dinnerDietFat, dinnerDietFibre } = dinnerOptions[optionsIndex];

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories according to quantity

                    dinnerDietCalorie = dinnerDietCalorie - calorieDeducted;
                    dinnerDietProtein = dinnerDietProtein - proteinDeducted;
                    dinnerDietCarbs = dinnerDietCarbs - carbsDeducted;
                    dinnerDietFat = dinnerDietFat - fatDeducted;
                    dinnerDietFibre = dinnerDietFibre - fibreDeducted;

                    dinnerOptions[optionsIndex].dinnerDietCalorie = dinnerDietCalorie.toFixed(1);
                    dinnerOptions[optionsIndex].dinnerDietProtein = dinnerDietProtein.toFixed(1);
                    dinnerOptions[optionsIndex].dinnerDietCarbs = dinnerDietCarbs.toFixed(1);
                    dinnerOptions[optionsIndex].dinnerDietFat = dinnerDietFat.toFixed(1);
                    dinnerOptions[optionsIndex].dinnerDietFibre = dinnerDietFibre.toFixed(1);
                }
                dinnerOptions[optionsIndex].dinnerDiet.splice(index, 1);
                this.setState({ dinnerOptions })
                break;

            case "bedTime":
                if (bedTimeOptions[optionsIndex].bedTimeDiet[index]
                    && bedTimeOptions[optionsIndex].bedTimeDiet[index].foodId) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = bedTimeOptions[optionsIndex].bedTimeDiet[index];

                    let { bedTimeDietCalorie, bedTimeDietProtein, bedTimeDietCarbs,
                        bedTimeDietFat, bedTimeDietFibre } = bedTimeOptions[optionsIndex];

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories according to quantity

                    bedTimeDietCalorie = bedTimeDietCalorie - calorieDeducted;
                    bedTimeDietProtein = bedTimeDietProtein - proteinDeducted;
                    bedTimeDietCarbs = bedTimeDietCarbs - carbsDeducted;
                    bedTimeDietFat = bedTimeDietFat - fatDeducted;
                    bedTimeDietFibre = bedTimeDietFibre - fibreDeducted;

                    bedTimeOptions[optionsIndex].bedTimeDietCalorie = bedTimeDietCalorie.toFixed(1);
                    bedTimeOptions[optionsIndex].bedTimeDietProtein = bedTimeDietProtein.toFixed(1);
                    bedTimeOptions[optionsIndex].bedTimeDietCarbs = bedTimeDietCarbs.toFixed(1);
                    bedTimeOptions[optionsIndex].bedTimeDietFat = bedTimeDietFat.toFixed(1);
                    bedTimeOptions[optionsIndex].bedTimeDietFibre = bedTimeDietFibre.toFixed(1);
                }
                bedTimeOptions[optionsIndex].bedTimeDiet.splice(index, 1);
                this.setState({ bedTimeOptions })
                break;

            default: break;
        }
    }

    handleClose = () => {
        let { handleClose } = this.props;
        this.setState({
            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            morningSnacksDiet: [],
            breakfastDiet: [],
            lunchDiet: [],
            dinnerDiet: [],
            midMorningDiet: [],
            midEveningDiet: [],
            bedTimeDiet: [],

            morningSnacksOptions: [],
            breakfastOptions: [],
            midMorningOptions: [],
            lunchOptions: [],
            midEveningOptions: [],
            dinnerOptions: [],
            bedTimeOptions: [],

            morningSnacksArray: [""],
            breakfastArray: [""],
            lunchArray: [""],
            dinnerArray: [""],
            midMorningArray: [""],
            midEveningArray: [""],
            bedTimeArray: [""],

            morningSnacksDietTime: "00:00",
            breakfastDietTime: "00:00",
            midMorningDietTime: "00:00",
            lunchDietTime: "00:00",
            midEveningDietTime: "00:00",
            dinnerDietTime: "00:00",
            bedTimeDietTime: "00:00",

            recommendedMorningSnacksCalories: 0,
            recommendedBreakfastCalories: 0,
            recommendedMidMorningCalories: 0,
            recommendedLunchCalories: 0,
            recommendedMidEveningCalories: 0,
            recommendedDinnerCalories: 0,
            recommendedBedTimeCalories: 0,

            validationError: '',
            morningSnacksDietCalorie: 0,
            breakfastDietCalorie: 0,
            lunchDietCalorie: 0,
            dinnerDietCalorie: 0,
            midMorningDietCalorie: 0,
            midEveningDietCalorie: 0,
            bedTimeDietCalorie: 0,
        })
        handleClose();
    }

    selectCalorieBreakdown = (selectedOption) => {
        new Promise(function (res, rej) {
            res(calorieBreakDown(selectedOption));
        })
            .then((result) => {
                let { recommendedMorningSnacksCalories,
                    recommendedBreakfastCalories,
                    recommendedMidMorningCalories,
                    recommendedLunchCalories,
                    recommendedMidEveningCalories,
                    recommendedDinnerCalories,
                    recommendedBedTimeCalories,
                    perDayCalories } = result;
                this.setState({
                    recommendedMorningSnacksCalories,
                    recommendedBreakfastCalories,
                    recommendedMidMorningCalories,
                    recommendedLunchCalories,
                    recommendedMidEveningCalories,
                    recommendedDinnerCalories,
                    recommendedBedTimeCalories,
                    perDayCalories
                })
            })
    }

    getAllFoodItems = (search) => {
        let self = this;
        let paramsObj = {
            params: { search }
        }
        return getAllFoods(paramsObj)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var foodItems = [];
                    response.responseData.result.map((food, index) => {
                        foodItems.push({
                            label: food.foodName + " (" + food.calorieContent + " cal)",
                            value: food._id,
                            calorie: food.calorieContent,
                            protein: food.proteinContent,
                            fat: food.fatContent,
                            carb: food.carbContent,
                            fibre: food.fibreContent,
                            foodUnit: food.foodUnit
                        });
                    });

                    self.setState({ foodItems })
                    return foodItems
                } else if (response.statusCode == 0) {
                    console.log(res);
                }
            })
    }

    componentDidMount() {
        this.getAllFoodItems()
    }

    handleInputChange = (val) => {
        if (val.length > 2) {
            this.getAllFoodItems(val)
        }
    }


    loadOptions = (val) => {
        let { typingTimeout } = this.state
        if (typingTimeout) clearTimeout(typingTimeout)
        let foodApiPromise = new Promise((resolve, reject) => { typingTimeout = setTimeout(() => { resolve(this.getAllFoodItems(val)) }, 1200) });
        this.setState({ typingTimeout })
        return foodApiPromise
    }

    calculateValueBasedOnQuantity(value, quantity){
        return quantity ? value * quantity : value;
    }


    render() {
        let { isOpenPopup } = this.props;

        let { foodItems, validationError, morningSnacksOptions, breakfastOptions, midMorningOptions, lunchOptions, midEveningOptions, dinnerOptions, bedTimeOptions, morningSnacksDietTime, breakfastDietTime, midMorningDietTime, lunchDietTime, midEveningDietTime, dinnerDietTime, bedTimeDietTime,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
        } = this.state;

        return (
            <Modal
                show={isOpenPopup}
                onHide={this.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Diet Plan
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Select
                        isSearchable
                        options={perDayCalories}
                        placeholder="Select a calorie break down"
                        onChange={(val) => this.selectCalorieBreakdown(val)}
                    />
                    <form onSubmit={this.submitAddDietPlan}>

                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Pre-Breakfast
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('morningSnack', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMorningSnacksCalories} onChange={this.handleOnChange} name="recommendedMorningSnacksCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="morningSnacksDietTime" value={morningSnacksDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>


                            {morningSnacksOptions.map((options, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Pre-Breakfast Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('morningSnack', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {options.morningSnacksDiet && options.morningSnacksDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('morningSnack', val, index, i)}
                                                />

                                                {/* <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('morningSnack', val, index, i)}
                                                /> */}
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('morningSnack', val, index, i)} min={1} max={20} />
                                            </div>
                                            {/* 1=pc, 2=glass,3=cup, 4=katori, 5=bowl, 6=mug */}

                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('morningSnack', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('morningSnack', val, index, i)} value={data.unit} disabled>
                                                    <option defaultValue="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} disabled step=".01"
                                                />
                                            </div>

                                        </div>))}


                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('morningSnack', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="morningSnacksDietCalorie"
                                                value={options.morningSnacksDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="morningSnacksDietProtein"
                                                value={options.morningSnacksDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="morningSnacksDietFat"
                                                value={options.morningSnacksDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="morningSnacksDietCarbs"
                                                value={options.morningSnacksDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="morningSnacksDietFibre"
                                                value={options.morningSnacksDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>



                        <div className="form_group_wrapper">
                            <h4 className="cm_pos_rel cm_orange">Break Fast
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('breakfast', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedBreakfastCalories} onChange={this.handleOnChange} name="recommendedBreakfastCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="breakfastDietTime" value={breakfastDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {breakfastOptions.map((options, i) => (
                                <React.Fragment>


                                    <h4 className="cm_pos_rel">Break Fast Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('breakfast', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {options.breakfastDiet && options.breakfastDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('breakfast', val, index, i)}
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('breakfast', val, index, i)} min={1} max={20} />
                                            </div>
                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('breakfast', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('breakfast', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01"
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01"
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>
                                        </div>))}

                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('breakfast', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="breakfastDietCalorie"
                                                value={options.breakfastDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="morningSnacksDietProtein"
                                                value={options.breakfastDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="breakfastDietFat"
                                                value={options.breakfastDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="breakfastDietCarbs"
                                                value={options.breakfastDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="breakfastDietFibre"
                                                value={options.breakfastDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>



                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Mid Morning
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('midMorning', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMidMorningCalories} onChange={this.handleOnChange} name="recommendedMidMorningCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="midMorningDietTime" value={midMorningDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {midMorningOptions.map((options, i) => (
                                <React.Fragment>

                                    <h4 className="cm_pos_rel">Mid Morning Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('midMorning', i)}><i className="fa fa-trash"></i></span>
                                    </h4>
                                    {options.midMorningDiet && options.midMorningDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('midMorning', val, index, i)}
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('midMorning', val, index, i)} min={1} max={20} />
                                            </div>
                                            {/* 1=pc, 2=glass,3=cup, 4=katori, 5=bowl, 6=mug */}


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('midMorning', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('midMorning', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>

                                        </div>))}

                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('midMorning', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                            </span>
                                    </div>


                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="midMorningDietCalorie"
                                                value={options.midMorningDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="midMorningDietProtein"
                                                value={options.midMorningDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="midMorningDietFat"
                                                value={options.midMorningDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="midMorningDietCarbs"
                                                value={options.midMorningDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="midMorningDietFibre"
                                                value={options.midMorningDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>



                        <div className="form_group_wrapper">
                            <h4 className="cm_pos_rel cm_orange">Lunch
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('lunch', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedLunchCalories} onChange={this.handleOnChange} name="recommendedLunchCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="lunchDietTime" value={lunchDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>



                            {lunchOptions.map((options, i) => (
                                <React.Fragment>
                                    <h4 className="cm_pos_rel">Lunch Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('lunch', i)}><i className="fa fa-trash"></i></span>
                                    </h4>
                                    {options.lunchDiet && options.lunchDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('lunch', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('lunch', val, index, i)} min={1} max={20} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('lunch', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('lunch', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>
                                        </div>))}

                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('lunch', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                            </span>
                                    </div>



                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="lunchDietCalorie"
                                                value={options.lunchDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="lunchDietProtein"
                                                value={options.lunchDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="lunchDietFat"
                                                value={options.lunchDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="lunchDietCarbs"
                                                value={options.lunchDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="lunchDietFibre"
                                                value={options.lunchDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>



                        <div className="form_group_wrapper">
                            <h4 className="cm_pos_rel cm_orange">Mid Evening
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('midEvening', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMidEveningCalories} onChange={this.handleOnChange} name="recommendedMidEveningCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="midEveningDietTime" value={midEveningDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>



                            {midEveningOptions.map((options, i) => (
                                <React.Fragment>

                                    <h4 className="cm_pos_rel">Mid Evening {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('midEvening', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {options.midEveningDiet && options.midEveningDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('midEvening', val, index, i)}
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('midEvening', val, index, i)} min={1} max={20} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('midEvening', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('midEvening', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>

                                        </div>))}



                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('midEvening', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                            </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="midEveningDietCalorie"
                                                value={options.midEveningDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="midEveningDietProtein"
                                                value={options.midEveningDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="midEveningDietFat"
                                                value={options.midEveningDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="midEveningDietCarbs"
                                                value={options.midEveningDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="midEveningDietFibre"
                                                value={options.midEveningDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>


                        <div className="form_group_wrapper">
                            <h4 className="cm_pos_rel cm_orange">Dinner
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('dinner', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedDinnerCalories} onChange={this.handleOnChange} name="recommendedDinnerCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="dinnerDietTime" value={dinnerDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>



                            {dinnerOptions.map((options, i) => (
                                <React.Fragment>

                                    <h4 className="cm_pos_rel">Dinner Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('dinner', i)}><i className="fa fa-trash"></i></span>
                                    </h4>
                                    {options.dinnerDiet && options.dinnerDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('dinner', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('dinner', val, index, i)} min={1} max={20} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('dinner', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('dinner', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>

                                        </div>))}


                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('dinner', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                            </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="dinnerDietCalorie"
                                                value={options.dinnerDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="dinnerDietProtein"
                                                value={options.dinnerDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="dinnerDietFat"
                                                value={options.dinnerDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="dinnerDietCarbs"
                                                value={options.dinnerDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="dinnerDietFibre"
                                                value={options.dinnerDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>))}
                        </div>


                        <div className="form_group_wrapper">
                            <h4 className="cm_pos_rel cm_orange">Bed Time
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('bedTime', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedBedTimeCalories} onChange={this.handleOnChange} name="recommendedBedTimeCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="bedTimeDietTime" value={bedTimeDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {bedTimeOptions.map((options, i) => (
                                <React.Fragment>


                                    <h4 className="cm_pos_rel">Bed Time Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('bedTime', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {options.bedTimeDiet && options.bedTimeDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>Food {index + 1}</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions={foodItems}
                                                    onChange={(val) => this.onFoodChange('bedTime', val, index, i)}
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control"
                                                    value={data.quantity}
                                                    onChange={(val) => this.onQtyChange('bedTime', val, index, i)} min={1} max={20} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('bedTime', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" onChange={(val) => this.onUnitChange('bedTime', val, index)} value={data.unit} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.protein, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fat, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.carb, data.quantity)} step=".01" />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={this.calculateValueBasedOnQuantity(data.fibre, data.quantity)} step=".01" />
                                            </div>

                                        </div>))}




                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('bedTime', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="bedTimeDietCalorie"
                                                value={options.bedTimeDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="bedTimeDietProtein"
                                                value={options.bedTimeDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="bedTimeDietFat"
                                                value={options.bedTimeDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="bedTimeDietCarbs"
                                                value={options.bedTimeDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="bedTimeDietFibre"
                                                value={options.bedTimeDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>))}
                        </div>

                        <div className="form_group_wrapper">
                            <h4 className="text-center text-danger">{validationError}</h4>
                            <button className="btn btn-success" type="submit">Submit For Review</button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default AddDietPlan;