import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux'

import Header from '../components/template/Header';
import { Loader } from '../components/Reuse';
import HelpVideoHeader from '../components/helpVideos/HelpVideoHeader'

import { getAllHelpVideosAction } from '../store/actions/helpVideos'

const count= 15

const initialState = {
    search: '',
    pageNo: 1,   
}  

class HelpVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        }
    }

    handleSearch = e => {
        this.setState({ ...initialState, search: e.target.value }, () => this.getAllHelpVideos())
    }

    handlePageChange = pageNumber => {
        this.setState({ pageNo: pageNumber }, () => this.getAllHelpVideos());
    }

    getAllHelpVideos = () => {
        const { getAllHelpVideos } = this.props
        let { search, pageNo} = this.state;

        let params = {
            params: {
                search,
                pageNo: pageNo - 1,
                count,
                role: 2
            }
        }
        getAllHelpVideos(params, search)
    }

    componentDidMount() {
        let { 
            match : { 
                params: { 
                    pageNo 
                    }
                }           
        } = this.props
        pageNo = pageNo ? pageNo : 1
        this.setState({pageNo: parseInt(pageNo)}, ()=>{
            this.getAllHelpVideos()
        })
    }

    render() {
        let {  
            search,  pageNo } = this.state;
        let { 
            helpVideosState : {
                helpVideos, 
                loader,
                totalCount,
                miniLoader
            }  
        } = this.props;
        return (
            <React.Fragment>
                {loader && <Loader />}
                <div className="page_wrapper">
                    <HelpVideoHeader 
                        title ="Help Videos"
                        placeholder ="Search with vidoe title"
                        totalCount={totalCount}
                        miniLoader={miniLoader}
                        search={search} 
                        handleSearch={this.handleSearch}         
                    />
                    <div className="call_schedules_body text-center">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Video Title</th>
                                    <th scope="col">Video Url</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Subcategory</th>
                                    <th scope="col">Created Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helpVideos.map((details, i) => (
                                    <tr key={i}>
                                        <th scope="row">{((i + 1) + ((pageNo - 1) * count))}</th>
                                        <td>{details.videoTitle}</td>
                                        <td>{details.videoUrl}
                                        <i className="cm_clone_icon fa fa-clone"
                                                        onClick={() => { navigator.clipboard.writeText(details.videoUrl) }} />
                                        </td>
                                        <td>{(details.category)?details.category.categoryTitle:'-'}</td>
                                        <td>{(details.subCategory)?details.subCategory.categoryTitle:'-'}</td>
                                        <td>{dateFormat(details.created, "d mmmm yyyy")}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        {helpVideos.length ? <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        /> : <p className="text-danger">No Users Found!</p>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    helpVideosState: state.helpVideosState
});

const mapDispatchToProps = dispatch => ({
    getAllHelpVideos: (params, search) => dispatch(getAllHelpVideosAction(params, search)),
})
export default connect(mapStateToProps,
mapDispatchToProps,
)(HelpVideos);
